export const actionSetLanguage = (lang) => ({
  type: 'set_language',
  payload: lang,
});

export const actionSetBrand = (brand) => ({
  type: 'set_brand',
  payload: brand,
});

export const actionSetWidget = (widget) => ({
  type: 'set_widget',
  payload: widget,
});

export const actionSetMobile = (mobile) => ({
  type: 'set_mobile',
  payload: mobile,
});
