import React from 'react';
import { t } from 'react-switch-lang';
import PageTitle from '../../helperFunctions/DocumentTitle';
import googleScript from '../../helperFunctions/GoogleAnalytics';
import SkipToNav from '../../components/skipToNav/SkipToNav';
import Navbar from '../../components/NavBar/NavBar';
import usingIE from '../../helperFunctions/UsingIE';
import Footer from '../../components/footer/Footer';
import { brandConfig } from '../../configurations/Brands';

// eslint-disable-next-line import/prefer-default-export
export function pageBuilder(pageName, Page, lang, brand, type, id) {
  const favicon = document.getElementById('favicon');
  favicon.href = brandConfig[brand].favicon;
  function userUsingIE() {
    if (usingIE()) {
      return (
        <div
          className="alert alert-warning stop-using-ie"
          role="alert"
          dangerouslySetInnerHTML={{ __html: t('IEWarningTxt') }}
        />
      );
    }
    return true;
  }
  return (
    <>
      <PageTitle />
      <div className="whole-screen">
        {googleScript()}
        <SkipToNav pageName={pageName} />
        <Navbar />
        {userUsingIE()}
        <main id="main-content">
          <Page brand={brand} lang={lang} id={id} />
        </main>
        <Footer id="my-footer" className="footer" />
      </div>
    </>
  );
}
