/* eslint-disable */

import dict from './theme/MasterConvertedCSS';

export function loadScript(src, id, onload) {
  // Create script
  const script = document.createElement("script");
  if (id) script.id = id;
  script.src = src;
  script.async = true;
  if (onload) script.onload = onload;

  // Add script to document body
  document.body.appendChild(script);
}

export function loadTheme(brand, mode) {

  const t1 = document.createElement("style");
  t1.type = "text/css";
  t1.innerHTML = dict[brand];
  document.body.appendChild(t1);

  if (mode === "mobilechat") {
    const t2 = document.createElement("style");
    t2.type = "text/css";
    t2.innerHTML = `
            .cx-buttons-window-control {
                display: none !important;
            }`;
    document.body.appendChild(t2);
  }

}

export function getIp() {
  return fetch('https://api.ipify.org?format=json')
    .then((response) => response.json())
    .then((res) => res.ip)
    .catch(() => "Unknown");
}

export function getBrowserName() {
  const navUserAgent = navigator.userAgent;
  let browserName = navigator.appName;
  let browserVersion = "" + parseFloat(navigator.appVersion);
  let tempNameOffset, tempVersionOffset, tempVersion;

  if ((tempVersionOffset = navUserAgent.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    browserVersion = navUserAgent.substring(tempVersionOffset + 6);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempVersionOffset = navUserAgent.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    browserVersion = navUserAgent.substring(tempVersionOffset + 5);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempNameOffset = navUserAgent.lastIndexOf(" ") + 1) < (tempVersionOffset = navUserAgent.lastIndexOf("/"))) {
    browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
    browserVersion = navUserAgent.substring(tempVersionOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  // trim version
  if ((tempVersion = browserVersion.indexOf(";")) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion);
  if ((tempVersion = browserVersion.indexOf(" ")) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion);

  return browserName;
}

export function validateEmail(email) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email);
}
