import React from 'react';
import { t } from 'react-switch-lang';

function PageTitle() {
  return (
    <div
      id="page-title"
      role="region"
      aria-live="polite"
      className="visually-hidden"
    >
      Help Center
    </div>
  );
}

export const DocumentTitle = (title) => {
  document.title = `${title} ${t('Help_Centre_Name')}`;
  const e = document.getElementById('page-title');
  if (e) e.textContent = title;
};

export default PageTitle;
