import React, { useEffect } from 'react';
import { t } from 'react-switch-lang';
import { Redirect, Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import $ from 'jquery';
import Search from '../../components/algolia/AlgoliaSearch';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import './Section.scss';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import { brandTitleGet } from '../../configurations/Brands';
import { makeLowerAndOnlyLetter } from '../../helperFunctions/LinkGenerator';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { DbConnect } from '../../helperFunctions/DbConnect';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';
import LoadingSpinner from '../../components/widget/widgetHelper/LoadingSpinner';

function Section(props) {
  const { sections, error, notFound, loading, categories, id } = props;
  const { brand, lang } = useSelector((state) => state);

  useEffect(() => {
    logAmpEvent(event.USER_VIEWED_SECTION, {
      'Section ID': id,
    });
  }, []);

  useEffect(() => {
    try {
      $('.ais-SearchBox-submit').attr('tabindex', -1);
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }, [loading]);

  if (notFound) {
    return <Redirect to={`/${brand}/${lang}/notFound`} />;
  }

  if (error) {
    return <Redirect to={`/${brand}/${lang}/error`} />;
  }

  if (loading) {
    return (
      <LoadingSpinner />
    );
  }

  function generateArticles() {
    const listItem = sections.Articles;
    const JSXList = [];
    for (let i = 0; i < listItem.length; i += 1) {
      if (listItem[i].Title.length !== 0) {
        JSXList.push(
          <li className="article-link" key={`sectionArticle-${i}`}>
            <Link
              className="hyperlink-alt"
              to={`/${brand}/${lang}/articles/${listItem[i].ArticleID}-${makeLowerAndOnlyLetter(listItem[i].Title)}`}
              onClick={() => {
                logAmpEvent(event.USER_CLICKED_SECTION_PAGE_ARTICLE_LINK, {
                  'Article ID': listItem[i].ArticleID,
                });
              }}
            >
              <ReplaceTextWithElement
                text={listItem[i].Title}
                replaceWith={<i>Interac</i>}
                replaceKey="Interac"
              />
              {' '}
            </Link>
          </li>
        );
      }
    }
    return JSXList;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function LayoutStyle() {
    return (
      <div className="horizontal-layout">
        <div className="left-layout" tabIndex="-1" />
        <div className="right-layout section-content">
          <div className="flex-container">
            <div id="section-heading">
              <h1 id={`${sections.Title}`} className="section-heading">
                <ReplaceTextWithElement
                  text={sections.Title}
                  replaceWith={<i>Interac</i>}
                  replaceKey="Interac"
                />
              </h1>
              <div className="section-description">
                <ReplaceTextWithElement
                  text={sections.Description}
                  replaceWith={<i>Interac</i>}
                  replaceKey="Interac"
                />
              </div>
            </div>
            <div className="flex-whitespace" />
            <ul aria-labelledby="section-heading">{generateArticles()}</ul>
          </div>
        </div>
      </div>
    );
  }

  DocumentTitle(
    t('PageTitle_Dynamic_Title')
      .replace('xBrand', brandTitleGet(brand, lang))
      .replace('xName', sections.Title)
  );

  return (
    <div>
      <div className="flex-box">
        <div className="flex-crumbs">
          <Breadcrumb
            sectionID={sections.SectionID}
            sectionName={sections.Title}
            categoryName={categories.Title}
            categoryID={sections.categoryID}
            lang={lang}
          />
        </div>

        <div id="search-content" className="flex-search">
          <Search mini />
        </div>
      </div>
      <div id="skip-link-content">
        <LayoutStyle section={sections} />
      </div>
    </div>
  );
}

export default DbConnect(Section, 'section');
