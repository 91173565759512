import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import WidgetFooter from './WidgetFooter';
import WidgetBrands from './WidgetBrands';
import WidgetListCategories from './WidgetListCategories';
import WidgetLanguage from './WidgetLanguage';
import WidgetCategories from './WidgetCategories';
import WidgetSections from './WidgetSections';
import WidgetArticle from './WidgetArticle';
import './WidgetHome.scss';
import WidgetTopBar from './WidgetTopBar';
import WidgetSortContact from './WidgetSortContact';
import { GenesysChat } from './chat/GenesysChat';
import PageTitle from '../../helperFunctions/DocumentTitle';
import usingIE from '../../helperFunctions/UsingIE';
import {
  actionSetWidget,
  actionSetLanguage,
  actionSetBrand,
} from '../../redux/Action';
import { checkBrandExists, checkBrandLangExists } from '../../configurations/Brands';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';

export default function WidgetHome({ match: { params: { brand, lang, type, id } } }) {
  const [filteringByText, setFilteringByText] = useState('');
  let isPageContact = false;
  let isMobile = false;
  const dispatch = useDispatch();

  // check localStorage. If DNE, change or remove icon
  // localStorage required for back btn

  if (!usingIE()) {
    dispatch(actionSetWidget('Widget'));
    dispatch(actionSetLanguage(lang));
    dispatch(actionSetBrand(brand));
  }

  function sortRoute() {
    // check if IE. If yes, dont set accessibility for buttons

    // route == /widget/:brand/:lang/:type/:id
    dispatch(actionSetLanguage(lang));
    dispatch(actionSetBrand(brand));
    if (brand === undefined) {
      return <WidgetBrands />;
    }
    if (checkBrandExists(brand) && lang === undefined) {
      return <WidgetLanguage brand={brand} />;
    }

    if (checkBrandLangExists(brand, lang) && type === undefined) {
      return <WidgetListCategories brand={brand} />;
    }

    if (type === 'category') {
      try {
        localStorage.setItem('category', id);
      } catch (e) {
        logAmpEvent(event.USER_DISABLED_LOCALSTORAGE);
      }
      return <WidgetCategories brand={brand} id={id} setFilteringByText={setFilteringByText} />;
    }

    // This route goes to article List
    if (type === 'section' || type === 'sections') {
      try {
        localStorage.setItem('section', id);
      } catch (e) {
        logAmpEvent(event.USER_DISABLED_LOCALSTORAGE);
      }

      return <WidgetSections id={id} setFilteringByText={setFilteringByText} />;
    }

    if (type === 'articles') {
      return <WidgetArticle id={id} />;
    }

    if (type === 'contactselect') {
      isPageContact = true;
      return <GenesysChat brand={brand} lang={lang} />;
    }

    if (type === 'contactus') {
      isPageContact = true;
      return <WidgetSortContact brand={brand} lang={lang} />;
    }
    return false;
  }

  if (window.location.href.indexOf('mobileapp') !== -1) {
    isMobile = true;
  }

  return (
    <div className="iframe-flex">
      <PageTitle />
      {/* eslint-disable-next-line max-len  */}
      <WidgetTopBar type={type} lang={lang} brand={brand} isMobile={isMobile} filteringByText={filteringByText} />
      <div className="widget-main-content">
        {sortRoute()}
      </div>
      <WidgetFooter isPageContact={isPageContact} brand={brand} lang={lang} />
    </div>
  );
}
