export const version = 'v1';

export const isDevelopment = () => process.env.REACT_APP_ENV === 'dev';

export const isRC = () => process.env.REACT_APP_ENV === 'rc';

export const baseUrl = () => `${process.env.REACT_APP_API_URL}/${version}`;

export const contactUrl = () => `${baseUrl()}/contact/submit`;

export const devConsoleLog = (string, location = '') => {
  if (isDevelopment()) {
    /* eslint-disable-next-line */
    console.log(`DEV DEBUG: \n:${string}\n${location}`);
  }
};
