export function hideWidget(shouldMoveFocus) {
  // sends post to parent
  window.parent.postMessage('hideWidget', '*');
  if (shouldMoveFocus) window.parent.postMessage('moveFocus', '*');
}

export function showWidget() {
  // sends post to parent
  window.parent.postMessage('showWidget', '*');
}
