import React from 'react';
import { Redirect } from 'react-router-dom';
import { checkBrandExists, checkBrandLangExists } from '../../configurations/Brands';
import NotFound from '../dynamicPages/NotFound';

function RouteTwo({ match: { params: { brand, lang } } }) {
  brand = brand.toLowerCase();
  lang = lang.toLowerCase();

  if (checkBrandExists(brand)) {
    if (lang === 'contactus' || lang === 'contact') {
      return <Redirect to={`/${brand}/en/contactus`} />;
    }

    if (checkBrandLangExists(brand, lang)) {
      return <Redirect to={`/${brand}/${lang}/categories`} />;
    }

    return <Redirect to={`/${brand}/en/categories`} />;
  }

  return <NotFound />;
}

export default RouteTwo;
