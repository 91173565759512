/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import gfm from 'remark-gfm';
import { t } from 'react-switch-lang';
import { Tooltip, OverlayTrigger, Table } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import LoadingSpinner from './widgetHelper/LoadingSpinner';
import './WidgetArticle.scss';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { brandTitleGet } from '../../configurations/Brands';
import WidgetError from './WidgetError';
import { DbConnect } from '../../helperFunctions/DbConnect';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';
import WidgetNotFound from './WidgetNotFound';

function WidgetArticle(props) {
  const { error, loading, notFound, article, id } = props;
  const { brand, lang } = useSelector((state) => state);

  const isMobile = window.location.href.includes('mobileapp');
  const mobileApp = isMobile ? '?mobileapp' : '';
  useEffect(() => {
    try {
      $('.ais-SearchBox-submit').attr('tabindex', -1);
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }, [loading]);

  const renderTooltip = () => (
    <Tooltip id="button-tooltip" {...props}>
      {t('LinkDescription_Article_External_Link', null, lang)}
    </Tooltip>
  );

  function CustomTable({ children }) {
    return <Table className="table-bordered">{children}</Table>;
  }
  if (notFound) return <WidgetNotFound brand={brand} lang={lang} />;
  if (error) return <WidgetError />;
  if (loading) return <LoadingSpinner />;

  function CustomHead({ children }) {
    return <th scope="col">{children}</th>;
  }

  // eslint-disable-next-line no-shadow
  function CustomLink(props) {
    const { href, children, title } = props;

    if (
      href.includes('localhost') ||
      href.includes('help.paymentsource.co') ||
      href.includes('help.paymentsource.net')
    ) {
      let path = href.replace(/^.*\/\/[^/]+/, '');
      const files = path.split('/');
      if (files.length >= 3) {
        path = `/widget/${files[1]}/${lang}${mobileApp}`;
        for (let i = 3; i < files.length; i += 1) {
          path += `/${files[i]}`;
        }
      }
      return <Link to={path} title={title}>{children}</Link>;
    }

    const cleanedHref = href.replace(/\*/g, '');

    return (title) ? (
      <a href={cleanedHref} rel="noreferrer" target="_blank" aria-label={title.replaceAll(/\*/g, '')}>
        {children}
      </a>
    ) : (
      <a href={cleanedHref} rel="noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  // eslint-disable-next-line no-shadow
  function CustomImg(props) {
    const { alt, src } = props;

    function altTag() {
      const re = /^[^#]*/;
      return alt.match(re);
    }

    function findWidth(text) {
      return (text.includes('#width'));
    }

    const cleanedSrc = src.replace(/\*/g, '');

    const first = alt.split(' ');
    const wtIdx = first.findIndex(findWidth);

    return (
      <img
        src={cleanedSrc}
        alt={altTag()}
        height="100%" // maintain proper aspect ratio for widget
        width={(wtIdx !== -1) ? first[wtIdx].replace('#width:', '') : '100%'}
      />
    );
  }
  const str = article.Body.replace(/(\r\n|\n)/g, '  \n');
  DocumentTitle(
    t('PageTitle_Dynamic_Title', null, lang)
      .replace('xBrand', brandTitleGet(brand, lang))
      .replace('xName', article.Title)
  );
  return (
    <div id="widget-article-flex-container">
      <div id="widget-breadcrumb">
        <span className="widget-breadcrumb__text">
          <ReplaceTextWithElement
            text={article.Category}
            replaceWith={<i>Interac</i>}
            replaceKey="Interac"
          />
        </span>
        <i className="widget-breadcrumb__chevron fal fa-xs fa-chevron-right" />
        <span className="widget-breadcrumb__text">
          <ReplaceTextWithElement
            text={article.Section}
            replaceWith={<i>Interac</i>}
            replaceKey="Interac"
          />
        </span>
      </div>

      <div id="widget-title-link-flex">
        <h1 className="widget-article-title">
          <ReplaceTextWithElement
            text={article.Title}
            replaceWith={<i>Interac</i>}
            replaceKey="Interac"
          />
        </h1>
        {!isMobile ? (
          <OverlayTrigger
            placement="top"
            rootClose
            delay={{ show: 250, hide: 250 }}
            overlay={renderTooltip}
          >
            <a
              href={`/${brand}/${lang}/articles/${id}`}
              target="_blank"
              rel="noreferrer"
              id="widget-ext-link"
              aria-label={t(
                'LinkDescription_Article_External_Link',
                null,
                lang
              )}
              onClick={() => {
                logAmpEvent(event.USER_CLICK_EXTERNAL_LINK_WIDGET, {
                  'Article ID': id,
                });
              }}
            >
              <i id="widget-link-icon" className="fal fa-external-link" />
            </a>
          </OverlayTrigger>
        ) : null}

      </div>
      <ReactMarkdown
        className="widget-article-content"
        remarkPlugins={[gfm]}
        linkTarget="_blank"
        /* eslint-disable-next-line react/no-children-prop */
        children={str
          .replaceAll(/(\r\n|\n)/g, '  \n')
          .replaceAll(/(\[)(Interac)(\])/g, '$1*$2*$3')
          .replaceAll(/(^|\s)(Interac)(\s|$)/g, '$1*$2*$3')
          .replaceAll(/(^|\s)(Interac)(ᴹᴰ)(\s|$)/g, '$1*$2*$3$4')
          .replaceAll(/(\*{1})(Interac)(\*{1})(ᴹᴰ)/g, '*$2*$4') // Handles one star (ensures it remains surrounded by one star)
          .replaceAll(/(\*{2,})(Interac)(\*{2,})(ᴹᴰ)?/g, '***$2***$4')}
        components={{
          table: CustomTable,
          th: CustomHead,
          a: CustomLink,
          link: CustomLink,
          img: CustomImg,
        }}
      />
    </div>
  );
}

export default DbConnect(WidgetArticle, 'article');
