import React from 'react';
import '../../../App.scss';

function LoadingSpinner() {
  return (
    <div>
      <div className="spinner-border" role="status">
        {/* Section required for SkipToNav to not crash */}
        <div id="skip-link-content" />
        <div id="ais-SearchBox-input" />
        <div id="sections-content" />

        {/* <span className="sr-only">{t('General_Loading')}</span> */}
      </div>
    </div>
  );
}

export default LoadingSpinner;
