import React, { useEffect } from 'react';
import { t } from 'react-switch-lang';
import Hero from './Hero';
import Features from './Features';
import { event, logAmpEvent } from '../../../helperFunctions/Amplitude';
import { DocumentTitle } from '../../../helperFunctions/DocumentTitle';

function MyeCash() {
  DocumentTitle(t('DocTitle_MyeCash'));
  useEffect(() => {
    logAmpEvent(event.USER_VIEWED_MYECASH);
  }, []);

  return (
    <>
      <Hero />
      <Features />
    </>
  );
}

export default MyeCash;
