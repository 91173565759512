import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { t } from 'react-switch-lang';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import WidgetError from './WidgetError';
import './WidgetCommon.scss';
import LoadingSpinner from './widgetHelper/LoadingSpinner';
import { brandTitleGet } from '../../configurations/Brands';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { DbConnect } from '../../helperFunctions/DbConnect';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';

function WidgetListCategories(props) {
  const { error, categories, loading } = props;
  const { brand, lang } = useSelector((state) => state);
  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  useEffect(() => {
    try {
      $('.ais-SearchBox-submit').attr('tabindex', -1);
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }, [loading]);

  function populateCategoryList() {
    const itemList = categories.Categories;
    const JSXobjectList = [];
    itemList.forEach((item) => {
      if (item.Status === 'A') {
        JSXobjectList.push(
          <Link
            className="widget-main-link"
            key={`l${item.Title}`}
            to={
              `/widget/${brand}/${lang}/category/${item.CategoryID}${isMobile}`
            }
            onClick={() => {
              logAmpEvent(event.USER_CLICKED_CATEGORY, {
                'Category ID': item.CategoryID,
              });
            }}

          >
            <Card res={`c${item.Title}`} className="widget-main-card">
              <ReplaceTextWithElement
                text={item.Title}
                replaceWith={<i>Interac</i>}
                replaceKey="Interac"
              />
            </Card>
          </Link>
        );
      }
    });
    return JSXobjectList;
  }

  DocumentTitle(
    t('PageTitle_Brand_Categories', null, lang).replace(
      'xBrand',
      brandTitleGet(brand, lang)
    )
  );

  if (error) return <WidgetError />;

  if (loading) return <LoadingSpinner />;
  return (
    <div className="widget-main-flex-container">
      {populateCategoryList()}
    </div>
  );
}

export default DbConnect(WidgetListCategories, 'categories');
