import React from 'react';
import { InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';
import { t, translate } from 'react-switch-lang';
import {
  ConfigureBrand,
  searchClient,
  indexNameDevelopment,
  CustomHits,
  CustomResults,
  useCollapsibleSearchResult,
} from '.';
import './AlgoliaSearch.scss';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { brandTitleGet } from '../../configurations/Brands';

function Search({ mini }) {
  const { brand, lang } = useSelector((state) => state);
  useCollapsibleSearchResult();
  return (
    <div className="ais-InstantSearch">
      <InstantSearch
        indexName={indexNameDevelopment}
        searchClient={searchClient}
      >
        <ConfigureBrand brand={brand} lang={lang} />
        <SearchBox
          inputId="algoliaSearchBox"
          className={mini ? 'mini-searchBox bordered' : 'searchBox ml-auto mr-auto'}
          searchAsYouType
          showLoadingIndicator
          translations={{
            placeholder: mini ? `${t('Search_Placeholder')}`.replace('xBrand', brandTitleGet(brand, lang)) : t('Algolia_Search_Placeholder_Articles'),
            submitTitle: `${t('IconDescription_AlgoliaSearchTitle')}`,
          }}
          onClick={() => { logAmpEvent(event.USER_CLICKED_SEARCH_BAR); }}
        />
        <div className={mini ? 'mini-results' : 'results'}>
          <CustomHits lang={lang} />
        </div>
        <CustomResults lang={lang} />
      </InstantSearch>
    </div>
  );
}

export default translate(Search);
