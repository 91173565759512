function reducer(state, action) {
  switch (action.type) {
    case 'set_language':
      return {
        ...state,
        lang: action.payload,
      };
    case 'set_brand':
      return {
        ...state,
        brand: action.payload,
      };
    case 'set_widget':
      return {
        ...state,
        widget: action.payload,
      };
    case 'set_mobile':
      return {
        ...state,
        mobile: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
