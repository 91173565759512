import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { setTranslations } from 'react-switch-lang';
import psiReducer from './redux/Reducer';
import App from './App';
import English from './languages/English.json';
import French from './languages/French.json';
import Chinese from './languages/Chinese.json';
import Korean from './languages/Korean.json';

import { isDevelopment, isRC } from './helperFunctions/config';

setTranslations({
  en: English,
  fr: French,
  zh: Chinese,
  ko: Korean,
});

const store = createStore(psiReducer);

function sentryInitialize() {
  if (isDevelopment() || isRC()) {
    // eslint-disable-next-line
    console.log('Sentry Paused in development');
  } else {
    Sentry.init({
      dsn: 'https://61e7208d5b2346a990b1ae262c1698ff@o292223.ingest.sentry.io/5794206',

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
    });
  }
}

ReactDOM.render(
  <Provider store={store}>
    {sentryInitialize()}
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
