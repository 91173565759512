import React from 'react';
import { Link } from 'react-router-dom';
import './WidgetLanguage.scss';
import { Card } from 'react-bootstrap';
import { brandConfig, languageText } from '../../configurations/Brands';

function mapLanguageList(brand) {
  const languageListJSX = [];
  brandConfig[brand].languages.forEach((lang) => {
    languageListJSX.push(
      <Link
        className="widget-language-link"
        key={lang}
        to={`/widget/${brand}/${lang}`}

      >
        <Card className="widget-language-card">
          {' '}
          {languageText[lang]}
          {' '}
        </Card>
      </Link>
    );
  });
  return languageListJSX;
}

function WidgetLanguage({ brand }) {
  return (
    <div id="widget-language-flex-container">{mapLanguageList(brand)}</div>
  );
}

export default WidgetLanguage;
