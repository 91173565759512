import $ from 'jquery';

export function setScreenReaderAlert(
  msg,
  appendTo = document.body,
  role = 'alert'
) {
  const alert = document.createElement('SPAN');
  alert.className = 'visually-hidden';
  alert.setAttribute('role', role);
  alert.innerHTML = msg;
  appendTo.appendChild(alert);

  if (process.env.REACT_APP_ENV !== 'prod') {
    // eslint-disable-next-line
    console.log(`Role with ${role} saying ${msg}`);
  }
}

export function moveFocusTo(id) {
  const el = document.getElementById(id);
  el.setAttribute('tabIndex', -1);
  el.focus();
}

export function moveFocusToClass(cls) {
  $(`.${cls}`).focus();
}
