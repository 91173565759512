import React from 'react';
import { t } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import '../../pages/dynamicPages/404AndErrorCommon.scss';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function WidgetNotFound() {
  const { brand, lang } = useSelector((state) => state);
  return (
    <div id="error-container" className="pt-0 mt-0">
      <h1 id="error-text" className="text-center">
        {t('Error_404', null, lang)}
      </h1>
      <Link to={`/widget/${brand}/${lang}/`}>
        <Button className={`error-go-home-btn ${brand}-button`}>
          {t('ReturnHome_Btn', null, lang)}
        </Button>
      </Link>
    </div>
  );
}

export default WidgetNotFound;
