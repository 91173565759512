import React from 'react';
import { useSelector } from 'react-redux';
import { t, translate } from 'react-switch-lang';
import './Hero.scss';
import appleApp from '../../../images/appStore/Apple.svg';
import googleApp from '../../../images/appStore/Google.svg';
import appleAppFr from '../../../images/appStore/AppleFr.png';
import googleAppFr from '../../../images/appStore/GoogleFr.png';
import eImage from '../../../images/myeCash/eCashBackground.png';
import { event, logAmpEvent } from '../../../helperFunctions/Amplitude';

function chooseAppLanguage(lang) {
  switch (lang) {
    case 'en':
      return (
        <>
          <a
            href={t('Link_MyeCash_AppleStore')}
            target="_blank"
            title={t('LinkDescription_MyeCash_AppleAppStore')}
            onClick={() => {
              logAmpEvent(
                event.USER_CLICKED_GET_MYECASH,
                { 'Download Option': 'Apple Store' }
              );
            }}
            rel="noreferrer"
          >
            <img
              className="m-2"
              src={appleApp}
              width="150"
              alt={t('ImageDescription_MyeCash_AppleAppStore')}
            />
          </a>
          <a
            href={t('Link_MyeCash_GooglePlay')}
            target="_blank"
            title={t('LinkDescription_MyeCash_GooglePlayStore')}
            onClick={() => {
              logAmpEvent(
                event.USER_CLICKED_GET_MYECASH,
                { 'Download Option': 'Google Play' }
              );
            }}
            rel="noreferrer"
          >
            <img
              className="m-2"
              src={googleApp}
              width="150"
              alt={t('ImageDescription_MyeCash_GooglePlayStore')}
            />

          </a>
        </>
      );
    case 'fr':
      return (
        <>
          <a
            href={t('Link_MyeCash_AppleStore')}
            target="_blank"
            title={t('LinkDescription_MyeCash_AppleAppStore')}
            onClick={() => {
              logAmpEvent(
                event.USER_CLICKED_GET_MYECASH,
                { 'Download Option': 'Apple Store' }
              );
            }}
            rel="noreferrer"
          >
            <img
              width="150"
              src={appleAppFr}
              className="m-2 appBtn"
              alt={t('ImageDescription_MyeCash_AppleAppStore')}
            />
          </a>
          <a
            target="_blank"
            href={t('Link_MyeCash_GooglePlay')}
            title={t('LinkDescription_MyeCash_GooglePlayStore')}
            onClick={() => {
              logAmpEvent(
                event.USER_CLICKED_GET_MYECASH,
                { 'Download Option': 'Google Play' }
              );
            }}
            rel="noreferrer"
          >
            <img
              className="m-2 appBtn"
              alt={t('ImageDescription_MyeCash_GooglePlayStore')}
              src={googleAppFr}
              width="150"
            />
          </a>
        </>
      );
    default:
      return null;
  }
}

function Hero() {
  const { lang } = useSelector((state) => state);

  return (
    <header id="hero">
      <div className="overlay">
        <div className="container mb-5 py-5">
          <div className="row eCashHero">
            <div className="offset-lg-2" />
            <div className="col-lg-6 ecash-title-container" id="skip-link-content">

              <h1 className="eCashHero__title mx-auto mb-0" id="eCashHero__title">
                {t('MyeCashTitle')}
              </h1>

              <span className="eCashHero__subtitle">
                {t('MyeCashSubtitle')}
              </span>

              <div className="eCashHero__buttons">
                {chooseAppLanguage(lang)}
              </div>

            </div>
            <div className="col-lg-3 ecash-img-container">
              <img src={eImage} className="ecash-img" alt={t('Img_eCashHero_Alt')} aria-hidden />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default translate(Hero);
