/* eslint-disable react/no-unstable-nested-components */

import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { t, setLanguage } from 'react-switch-lang';
import { event as ampEvent, logAmpEvent } from '../../../helperFunctions/Amplitude';
import './GenesysChat.scss';
import {
  loadScript,
  validateEmail,
  getIp,
  getBrowserName,
  loadTheme,
} from './ChatUtil';
import { brandConfig } from '../../../configurations/Brands';
import { isDevelopment } from '../../../helperFunctions/config';
import WidgetSortContact from '../WidgetSortContact';
import WidgetTopBar from '../WidgetTopBar';

function isPhone(event, form, input) {
  if (!input) return true;
  if (!input.val()) return false;
  if (!/^(?=(\D*\d){10,}).{10,15}$/.test(input.val())) return false;
  return true;
}
function isRequired(event, form, input) {
  if (!input) return true;
  if (!input.val()) return false;
  return true;
}
function isEmail(event, form, input) {
  if (!input) return true;
  if (!input.val()) return false;
  if (!validateEmail(input.val())) return false;
  return true;
}

function isValidTheme(theme) {
  if (theme.toLowerCase() === 'light' || theme.toLowerCase() === 'dark') {
    return theme;
  }
  return 'light';
}

// eslint-disable-next-line import/prefer-default-export
export function GenesysChat(props) {
  let mode;
  let urlParam;
  let theme;
  if (window.location.href.toLowerCase().includes('mobilechat')) {
    mode = 'mobilechat';
    // eslint-disable-next-line react/destructuring-assignment
    urlParam = props.match.params;
    theme = ((urlParam.theme) ? isValidTheme(urlParam.theme) : 'light');
  } else {
    mode = 'widget';
    urlParam = props;
  }
  const [lang] = useState(urlParam.lang);
  const [brand] = useState(urlParam.brand);
  /* if PaySimply or Maple or Zenwallet
   redirect to contact us completely ignoring the chat selection window
  */
  if (brand.toLowerCase() === 'paysimply' || brand.toLowerCase() === 'canadapost-prepaid' || brand.toLowerCase() === 'zenwallet') {
    return (
      <div className="iframe-flex">
        {mode === 'mobilechat' && (
        <WidgetTopBar type="contactus" lang={urlParam.lang} brand={urlParam.brand} isMobile="true" />
        )}
        <div className={mode === 'mobilechat' ? 'widget-main-content' : ''}>
          <WidgetSortContact brand={urlParam.brand} lang={urlParam.lang} removeAccount={mode === 'mobilechat' ? 'true' : undefined} />
        </div>
      </div>
    );
  }

  if (mode === 'mobilechat') {
    setLanguage(lang);
    return (
      <ChatWidget
        brand={brand}
        forWeb={false}
        lang={lang}
        theme={theme}
        isWidget
        mode={mode}
      />
    );
  }
  const showChat = (brandConfig[brand].genesysDeploymentKey.length !== 0);
  return (
    <ChatWidget
      showChat={showChat}
      brand={brand}
      lang={lang}
      mode={mode}
      forWeb
    />
  );
}

function ChatWidget({
  brand,
  lang = 'en',
  forWeb = true,
  email,
  theme = 'light',
  showChat,
  isWidget,
  mode,
}) {
  const [customPluginAccessor, setCustomPluginAccessor] = useState(null);
  const [btnVisible, setBtnVisible] = useState(false);
  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  async function initGenesys() {
    const businessPhoneField = {
      id: 'cx_webchat_form_phonenumber',
      name: 'phone',
      maxlength: '100',
      placeholder: t(
        'GenesysChatWidget_Phone_Placeholder',
        null,
        lang
      ),
      label: t('GenesysChatWidget_Phone_Label', null, lang),
      wrapper: '<div>{label}</div><div>{input}</div>',
      validate(event, form, input, label, $, CXBus, Common) {
        return isPhone(event, form, input, label, $, CXBus, Common);
      },
    };
    const emailField = {
      id: 'cx_webchat_form_email',
      name: 'email',
      maxlength: '100',
      wrapper: '<div>{label}</div><div>{input}</div>',
      placeholder: t(
        'GenesysChatWidget_Email_Placeholder',
        null,
        lang
      ),
      label: t('GenesysChatWidget_Email_Label', null, lang),
      value: email,
      validate: isEmail,
    };

    function changeCloseToButtonText() {
      const originalCloseBtn = document.getElementsByClassName('cx-button-close')[0];
      // originalCloseBtn.removeChild(originalCloseBtn.childNodes[0]);
      originalCloseBtn.style.width = '100px';
      originalCloseBtn.style.height = '25px';
      originalCloseBtn.className = 'cx-icon cx-button-close cx-btn-primary cx-btn';
      originalCloseBtn.innerText = t('GenesysChatWidget_Mobile_EndChat', null, lang);
    }
    function setPlugin() {
      const customPlugin = window.CXBus.registerPlugin('Custom');
      setCustomPluginAccessor(customPlugin);

      customPlugin.subscribe('WebChatService.started', () => {
        changeCloseToButtonText(lang);
        logAmpEvent(ampEvent.GENESYS_CHAT_STARTED);
      });

      customPlugin.subscribe('WebChatService.ended', () => {
        logAmpEvent(ampEvent.GENESYS_CHAT_ENDED);
      });

      if (forWeb) {
        setBtnVisible(true);
      } else {
        customPlugin.subscribe('WebChat.opened', () => {
          const container = document.getElementById('center-cont');
          if (!container) return;
          container.firstElementChild.remove();
          container.style.padding = '0 10%';

          const title = document.createElement('h1');
          title.style.fontFamily = 'Roboto';
          title.style.textAlign = 'center';
          title.style.fontWeight = 'normal';
          title.textContent = 'yes';
          container.appendChild(title);

          const body = document.createElement('p');
          body.style.fontFamily = 'Roboto';
          body.style.marginTop = '0';
          body.textContent = 'no';
          container.appendChild(body);
          setBtnVisible(true);
        });
        customPlugin.subscribe('WebChat.closed', () => setBtnVisible(false));
        customPlugin.command('WebChat.open');
      }
    }

    const nowPrepayShowPhone = (brand === 'nowprepay');
    // eslint-disable-next-line no-underscore-dangle
    window._genesys = {
      widgets: {
        main: {
          // debug: true,
          downloadGoogleFont: true,
          lang,
          i18n: 'https://apps.mypurecloud.com/widgets/9.0/i18n/widgets-fr.i18n.json',
          theme,
          preload: ['WebChat'], // needed for custom button
        },
        onReady: setPlugin,
        webchat: {
          emojis: true,
          userData: {
            // used for chat flow
            customField1Label: 'Language',
            customField1: lang === 'fr' ? 'French' : 'English',
            customField2Label: 'Platform',
            customField2: forWeb ? 'Web' : 'Mobile',
            customField3Label: 'User Info',
            customField3: `${
              forWeb ? `Browser: ${getBrowserName()} | ` : ''
            }IP Address: ${await getIp()}`,
          },
          transport: {
            type: 'purecloud-v2-sockets',
            dataURL: 'https://api.cac1.pure.cloud',
            deploymentKey: (isDevelopment() ? '09dcc60f-01bf-4f75-8e4f-79db6a79f85f' : brandConfig[brand].genesysDeploymentKey),
            orgGuid: '117495ab-a877-4453-be5e-ea509b47c39e',
            interactionData: {
              routing: {
                targetType: 'QUEUE',
                priority: 2,
              },
            },
          },
          form: {
            wrapper: '<div style="margin-bottom: 15px;"></div>',
            inputs: [
              // Default fields
              {
                id: 'cx_webchat_form_firstname',
                name: 'firstname',
                maxlength: '100',
                wrapper: '<div>{label}</div><div>{input}</div>',
                placeholder: t(
                  'GenesysChatWidget_Name_Placeholder',
                  null,
                  lang
                ),
                label: t('GenesysChatWidget_Name_Label', null, lang),
                validate: isRequired,
              },
              (nowPrepayShowPhone) ? businessPhoneField : emailField,
            ],
          },
        },
      },
    };
  }

  function resetGenesys() {
    setBtnVisible(false);
    document
      .querySelectorAll('script#genesys')
      .forEach((e) => e.parentNode.removeChild(e));
    // remove DOM element
    document
      .querySelectorAll('.cx-widget.cx-window-manager')
      .forEach((e) => e.parentNode.removeChild(e));
    // delete global variables
    delete window.CXBus;
    // eslint-disable-next-line no-underscore-dangle
    delete window._genesys;
  }

  function genesysOnload() {
    window.CXBus.configure({
      pluginsPath: 'https://apps.mypurecloud.com/widgets/9.0/plugins/',
    });
    window.CXBus.loadPlugin('widgets-core');
  }

  useEffect(() => {
    loadTheme(brand, mode);
    // eslint-disable-next-line no-underscore-dangle
    if (window._genesys) resetGenesys();
    initGenesys().then(() => loadScript(
      'https://apps.mypurecloud.com/widgets/9.0/cxbus.min.js',
      'genesys',
      genesysOnload
    ));
    // eslint-disable-next-line
  }, [lang]);

  function handleEndChatBtn() {
    document.getElementsByClassName('center-of-screen')[0].style.visibility =
      'visible';
    return <ChatEndedMessage />;
  }

  function hideMinimizeBtn() {
    // Hide Minimize
    document.getElementsByClassName('cx-button-minimize')[0].style.visibility =
      'hidden';
    document.getElementsByClassName('cx-button-minimize')[0].style.width = '0px';
  }

  function openChat() {
    customPluginAccessor.command('WebChat.open').done(() => {
      hideMinimizeBtn(lang);
    });
  }

  function WebChatFloatBtn() {
    return (
      <Card
        className="widget-contact-us"
        onClick={() => {
          openChat();
          logAmpEvent(ampEvent.USER_CLICK_WIDGET_GENESYS_CHAT);
        }}
      >
        <i className="fal fa-comments fa-2x widget-icon-contact" />
        <span className="v-align">
          {t('Select_Contact_Chat', null, lang)}
          {' '}
        </span>
      </Card>
    );
  }

  function ChatEndedMessage() {
    return (
      <div className="center-of-screen">
        <h1>{t('GenesysChatWidget_Mobile_ReturnToAppTitle')}</h1>
        <p>{t('GenesysChatWidget_Mobile_ReturnToAppBody')}</p>
      </div>
    );
  }

  function endChat() {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('GenesysChatWidget_Mobile_ConfirmEnd'))) {
      handleEndChatBtn();
      customPluginAccessor.command('WebChatService.endChat');
      customPluginAccessor.command('WebChat.close');
    }
  }

  if (isWidget) {
    return (
      <>
        <button id="end-chat-btn" onClick={endChat} type="button" style={{ color: brandConfig[brand].mobileMode.endChatBtn[theme] }}>
          {t('GenesysChatWidget_Mobile_EndChat')}
        </button>
        <ChatEndedMessage />
      </>

    );
  }

  return (
    <>
      <Link
        className="widget-main-link"
        to={`/widget/${brand}/${lang}/contactus${isMobile}`}
      >
        <Card
          className="widget-contact-us"
          onClick={() => logAmpEvent(ampEvent.USER_CLICK_WIDGET_CONTACT_US_NOT_FOOTER)}
        >
          <i className="fal fa-file-alt fa-2x widget-icon-contact widget-file-icon" />
          <span className="v-align">
            {' '}
            {t('Select_Contact_Form', null, lang)}
          </span>
        </Card>
      </Link>

      {showChat && btnVisible && (forWeb ? (<WebChatFloatBtn lang={lang} />
      ) : (
        <WebChatFloatBtn lang={lang} />
      ))}
    </>
  );
}
