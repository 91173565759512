import React from 'react';
import 'instantsearch.css/themes/satellite.css';
import { useSelector } from 'react-redux';
import Search from '../algolia/AlgoliaSearch';
import './Banner.scss';

const howHelpLanguage = {
  en: 'How can we help?',
  fr: 'Comment pouvons nous aider?',
};

function Banner({ lang }) {
  if (lang === undefined) {
    lang = useSelector((state) => state);
  }
  return (
    <div id="search-content" className="banner">
      <h1 id="howCanWeHelpID">
        <label htmlFor="algoliaSearchBox">
          {howHelpLanguage[lang] || howHelpLanguage.en}
        </label>
      </h1>
      <Search />
    </div>
  );
}

export default Banner;
