import React from 'react';
import './Breadcrumb.scss';
import { Link } from 'react-router-dom';
import { t, translate } from 'react-switch-lang';
import { makeLowerAndOnlyLetter } from '../../helperFunctions/LinkGenerator';
import { brandTitleGet } from '../../configurations/Brands';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';

function BreadcrumbItem({
  brand,
  lang,
  type,
  id,
  title,
  onClick,
  active = true,
}) {
  let paths = '';
  [brand, lang, type, id, title].find((path, i) => {
    if (!path) return true;
    paths += i !== 4 ? `/${path}` : `-${makeLowerAndOnlyLetter(path)}`;
    return false;
  });

  return (
    <>
      {type && (
        <i
          className="chevron-style fal fa-2x fa-chevron-right"
          aria-label={t('IconDescription_Breadcrumb_Chevron')}
        />
      )}
      <li>
        {active ? (
          <Link
            className="hyperlink breadcrumb-link"
            to={paths}
            onClick={onClick}
          >
            <ReplaceTextWithElement
              text={title}
              replaceWith={<i>Interac</i>}
              replaceKey="Interac"
            />
          </Link>
        ) : (
          <span className="breadcrumb-link">
            <ReplaceTextWithElement
              text={title}
              replaceWith={<i>Interac</i>}
              replaceKey="Interac"
            />
          </span>
        )}
      </li>
    </>
  );
}

function Breadcrumb({
  categoryID,
  categoryName,
  sectionID,
  sectionName,
  lang,
  lastActive,
  articleStatus,
}) {
  if (articleStatus === 'H') return null;

  const urlPath = window.location.pathname;
  const brand = urlPath.match(/\/([^/]*)/)[1];
  const propsList = [];

  propsList.push({
    title: brandTitleGet(brand, lang),
    onClick: () => logAmpEvent(event.USER_CLICKED_BREADCRUMB_TO_BRAND_PAGE),
  });
  if (categoryID) {
    propsList.push({
      type: 'categories',
      id: categoryID,
      title: categoryName,
      onClick: () => logAmpEvent(event.USER_CLICKED_BREADCRUMB_TO_CATEGORY_PAGE, {
        'Category ID': categoryID,
      }),
    });
  }
  if (sectionID) {
    propsList.push({
      type: 'sections',
      id: sectionID,
      title: sectionName,
      onClick: () => logAmpEvent(event.USER_CLICKED_BREADCRUMB_TO_SECTION_PAGE, {
        'Section ID': sectionID,
      }),
    });
  }
  if (!lastActive) propsList[propsList.length - 1].active = false;

  return (
    <div className="custom-breadcrumb">
      <nav
        aria-label={t('LinkDescription_Breadcrumb')}
        id="breadcrumbs"
        role="navigation"
      >
        <div className="breadcrumb-text">
          <ol>
            {propsList.map((props) => (
              <BreadcrumbItem
                brand={brand}
                lang={lang}
                {...props}
                key={props.type ?? 'brand'}
              />
            ))}
          </ol>
        </div>
      </nav>
    </div>
  );
}

export default translate(Breadcrumb);
