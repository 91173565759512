import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { t, translate } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import './ContactUsNowPrepay.scss';
import './contactusform.scss';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { event as ampEvent, event, logAmpEvent } from '../../../helperFunctions/Amplitude';
import {
  SubmitContactUs,
  hideContactError,
  showErrorFromList,
  validateDescriptionBody,
  validatePhone, validateEmail, validateName,
} from '../../../helperFunctions/SubmitContactUs';
import { setScreenReaderAlert } from '../../../helperFunctions/Accessibility';
import RadioBuilder from './RadioBuilder';
import { performSearch } from '../../../components/algolia/AlgoliaUtils';
import RelevantFAQ from './RelevantFAQ';

function ContactUsNowPrepay({ widgetMode }) {
  const { brand, lang } = useSelector((state) => state);
  const emailRef = useRef();
  const nameRef = useRef();
  const bodyRef = useRef();
  const phoneRef = useRef();
  const [appId, setAppId] = useState('');
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [disable, setDisable] = React.useState(false);

  const [emailValidateOnChange, setEmailValidateOnChange] = useState(false);
  const [descriptionValidateOnChange, setDescriptionValidateOnChange] = useState(false);
  const [validatePhoneOnChange, setValidatePhoneOnChange] = useState(false);
  const [validateNameOnChange, setValidateNameOnChange] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestedArticleAlertRead, setSuggestedArticleAlertRead] = useState(false);

  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  useEffect(() => {
    if (widgetMode) {
      document.getElementById('desktop-contact-us').style.padding = '0px';
    }
    logAmpEvent(event.USER_VIEWED_CONTACT_SCREEN);
  }, []); //eslint-disable-line

  useEffect(() => {
    if (appId !== '') {
      logAmpEvent(event.USER_SELECTED_APP_USED, { App: appId });
    }
  }, [appId]);

  useEffect(() => {
    async function updateSearchResults() {
      const results = await performSearch(searchTerm, brand, lang);
      if (results.length > 0) {
        setSuggestedArticleAlertRead(true);
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_Suggested_Article', null, lang));
      } else if (suggestedArticleAlertRead) {
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_No_Suggested_Article', null, lang));
      }
      setSearchResults(results);
    }
    const timer = setTimeout(updateSearchResults, 700);
    return () => { clearTimeout(timer); };
  }, [searchTerm, lang]);

  function sendAPI() {
    const data = {
      BrandID: appId,
      CustomerEmail: emailRef.current.value,
      Name: nameRef.current.value,
      PhoneNumber: phoneRef.current.value,
      Language: lang,
      Body: bodyRef.current.value,
    };

    let sending = document.getElementById('submit-button');
    sending.innerHTML = t('ContactForm_Submitting');
    setDisable(true);

    SubmitContactUs(data).then(
      () => {
        setShow(false);
        document.getElementById('desktop-contact-us').style.visibility = 'hidden';
        document.getElementById('successMessage').style.visibility = 'visible';
        document.getElementById('successMessage').style.display = 'block';
        const focus = document.getElementById('successMessage');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },
      () => {
        logAmpEvent(event.USER_RECEIVED_CONTACT_SERVER_ERROR, { message: error });
        setError(t('Error_Something_Went_Wrong'));
        setShow(true);
        sending = document.getElementById('submit-button');
        sending.innerHTML = t('ContactForm_Submit_button');
        setDisable(false);
        const focus = document.getElementById('error_message');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    );
  }

  function handleSubmit(e) {
    setDescriptionValidateOnChange(true);
    setValidatePhoneOnChange(true);
    setValidateNameOnChange(true);
    setEmailValidateOnChange(true);

    const errorList = [];

    e.preventDefault();
    logAmpEvent(event.USER_CLICKED_SUBMIT);

    const emailError = validateEmail(emailRef);
    if (emailError !== undefined) {
      document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
      errorList.push(emailError);
    }

    const phoneError = validatePhone(phoneRef);
    if (phoneError !== undefined) {
      document.getElementsByClassName('phone-attr')[0].classList.add('is-invalid');
      errorList.push(phoneError);
    }

    const nameError = validateName(nameRef);
    if (nameError !== undefined) {
      document.getElementsByClassName('name-attr')[0].classList.add('is-invalid');
      errorList.push(nameError);
    }

    if (appId === '') {
      errorList.push('app_error_message');
    } else {
      hideContactError('app_error_message');
    }

    if (validateDescriptionBody(bodyRef) !== undefined) {
      document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
      errorList.push(validateDescriptionBody(bodyRef));
    }
    showErrorFromList(errorList, widgetMode);

    if (errorList.length === 0) {
      sendAPI();
      setScreenReaderAlert(t('ScreenReader_ContactForm_Success'));
    } else {
      logAmpEvent(ampEvent.USER_ERROR_CONTACT, { 'Error Type': errorList });
      setScreenReaderAlert(t('ScreenReader_ContactForm_Error'));
    }
  }

  function handleClick(target) {
    hideContactError('app_error_message');
    switch (target) {
      case 'r1':
        setAppId('NowPrepay Web Store');
        break;
      case 'r2':
        setAppId('NowPrepay Android POS');
        break;
      case 'r3':
        setAppId('NowPrepay Verifone POS');
        break;
      case 'r4':
        setAppId('NowPrepay Retailer Central');
        break;
      case 'r5':
        setAppId('NowPrepay eCash Web');
        break;
      case 'r6':
        setAppId('NowPrepay My eCash');
        break;
      case 'r7':
        setAppId('NowPrepay Other/Not applicable');
        break;
      default:
        break;
    }
  }

  const handleTextareaChange = (e) => {
    setSearchTerm(e.target.value);
  };

  function replaceTextKeyup() {
    let phone = $('#phone-number').val();
    phone = (!phone) ?
      '' :
      phone.replace(/[^\d-]/g, '')
        .replace(/^(.{12})(.*)$/g, '$1')
        .replace(/^(\d{3})(\d{1})$/, '$1-$2')
        .replace(/^(\d{3})-(\d{3})(\d{1})$/, '$1-$2-$3')
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
    $('#phone-number').val(phone);
  }

  return (
    <>
      {show && (
        <Alert
          variant="danger"
          id="error_message"
          className="mt-3"
          onClose={() => {
            setShow(false);
            setError('');
          }}
          dismissible
        >
          <p style={{ marginBottom: 0 }}>{error}</p>
        </Alert>
      )}
      <div id="successMessage">
        <h1>{t('ContactForm_Submitted')}</h1>
        <Link to={(widgetMode) ? `/widget/nowprepay/${lang}${isMobile}` : `/nowprepay/${lang}/categories`}>
          <Button className={`error-go-home-btn ${brand}-button`}>
            {t('ReturnHome_Btn')}
          </Button>
        </Link>
      </div>
      <div id="desktop-contact-us">
        <div id="skip-link-content">
          <h1>{t('General_Contact_Us')}</h1>
          <p className="contact-label-suggestion">
            {t('ContactForm_Fields_Required')}
          </p>

          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Email_"
              required
            >
              <fieldset>
                <Form.Label
                  as="legend"
                  id="aria-email"
                  className="form-labels"
                  style={{ marginBottom: '0' }}
                >
                  {t('ContactForm_General_Email_Label')}
                </Form.Label>

                <Form.Control
                  type="text"
                  className="email-attr"
                  aria-labelledby="aria-email"
                  ref={emailRef}
                  onBlur={() => {
                    setEmailValidateOnChange(true);
                    const curError = validateEmail(emailRef);
                    if (curError !== undefined) {
                      document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
                      showErrorFromList(curError);
                    } else {
                      if (document.getElementsByClassName('email-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('email-attr')[0].classList.remove('is-invalid');
                      }
                      showErrorFromList(validateEmail(emailRef));
                    }
                  }}
                  onChange={() => {
                    if (emailValidateOnChange) {
                      const curError = validateEmail(emailRef);
                      if (curError !== undefined) {
                        document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else if (document.getElementsByClassName('email-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('email-attr')[0].classList.remove('is-invalid');
                      }
                    }
                  }}
                />
                <Form.Text
                  id="email_empty_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Email_Error')}
                </Form.Text>
                <Form.Text
                  id="email_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Email_Character_Length_Error')}
                </Form.Text>
                <Form.Text
                  id="email_invalid"
                  className="error-handling-messages"
                >
                  {t('ContactForm_General_Email_Validation')}
                </Form.Text>
              </fieldset>
            </Form.Group>

            <Form.Group
              className="form-groups"
              controlId="ContactForm_Name"
              required
            >
              <fieldset>
                <Form.Label id="aria-name" as="legend" className="form-labels">
                  {t('ContactForm_Name')}
                </Form.Label>
                <Form.Control
                  type="text"
                  aria-labelledby="aria-name"
                  ref={nameRef}
                  className="name-attr"
                  onBlur={() => {
                    setValidateNameOnChange(true);
                    const curError = validateName(nameRef);
                    if (curError !== undefined) {
                      document.getElementsByClassName('name-attr')[0].classList.add('is-invalid');
                      showErrorFromList(curError);
                    } else if (document.getElementsByClassName('name-attr')[0].classList.contains('is-invalid')) {
                      document.getElementsByClassName('name-attr')[0].classList.remove('is-invalid');
                    }
                  }}
                  onChange={() => {
                    if (validateNameOnChange) {
                      const curError = validateName(nameRef);
                      if (curError !== undefined) {
                        document.getElementsByClassName('name-attr')[0].classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else if (document.getElementsByClassName('name-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('name-attr')[0].classList.remove('is-invalid');
                      }
                    }
                  }}
                />
                <Form.Text
                  id="name-required"
                  className="error-handling-messages"
                >
                  {t('ContactForm_General_Validation_Required')}
                </Form.Text>
                <Form.Text
                  id="name-length-error"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Length_Error_50')}
                </Form.Text>
              </fieldset>
            </Form.Group>

            <Form.Group
              className="form-groups"
              controlId="ContactForm_Phone_Number"
              required
            >
              <fieldset>
                <Form.Label
                  as="legend"
                  className="form-labels"
                  id="aria-phone"
                  style={{ marginBottom: '0' }}
                >
                  {t('ContactForm_Phone_Number')}
                </Form.Label>
                <p className="contact-label-suggestion">999-999-9999</p>
                <Form.Control
                  className="phone-attr"
                  id="phone-number"
                  aria-labelledby="aria-phone"
                  ref={phoneRef}
                  placeholder="999-999-9999"
                  onBlur={() => {
                    setValidatePhoneOnChange(true);
                    const curError = validatePhone(phoneRef);
                    if (curError !== undefined) {
                      document
                        .getElementsByClassName('phone-attr')[0]
                        .classList.add('is-invalid');
                      showErrorFromList(curError);
                    } else {
                      if (
                        document
                          .getElementsByClassName('phone-attr')[0]
                          .classList.contains('is-invalid')
                      ) {
                        document
                          .getElementsByClassName('phone-attr')[0]
                          .classList.remove('is-invalid');
                      }
                      showErrorFromList(validatePhone(phoneRef));
                    }
                  }}
                  onChange={() => {
                    if (validatePhoneOnChange) {
                      const curError = validatePhone(phoneRef);
                      if (curError !== undefined) {
                        document
                          .getElementsByClassName('phone-attr')[0]
                          .classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else if (document.getElementsByClassName('phone-attr')[0].classList.contains('is-invalid')
                      ) {
                        document
                          .getElementsByClassName('phone-attr')[0]
                          .classList.remove('is-invalid');
                      }
                    }
                  }}
                  onKeyUp={replaceTextKeyup}
                />
                <Form.Text
                  id="phone-empty-error"
                  className="error-handling-messages"
                >
                  {t('ContactForm_General_Validation_Required')}
                </Form.Text>
                <Form.Text
                  id="phone-validation-error"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Invalid_Phone_Number')}
                </Form.Text>
              </fieldset>
            </Form.Group>

            {/* Which app did you use? */}
            <Form.Group
              className="form-groups"
              name="ContactForm_AppID_"
              controlId="ContactForm_AppID_"
              required
            >

              <fieldset className="nowprepay-radio">
                <Form.Label id="app_label" as="legend" className="form-labels">
                  {t('ContactForm_General_AppID_Label')}
                </Form.Label>

                <div className="nowprepay-app-div">
                  <RadioBuilder
                    value="r1"
                    clickHandler={handleClick}
                    content={t('ContactForm_AppID_NowPrepay_WebStore')}
                  />

                  <RadioBuilder
                    value="r5"
                    clickHandler={handleClick}
                    content={t('ContactForm_AppID_NowPrepay_eCashWeb')}
                  />
                  <RadioBuilder
                    value="r4"
                    clickHandler={handleClick}
                    content={t('ContactForm_AppID_NowPrepay_RetailerCentral')}
                  />
                </div>
                <div className="nowprepay-app-div">

                  <RadioBuilder
                    value="r2"
                    clickHandler={handleClick}
                    content={t('ContactForm_AppID_NowPrepay_SunmiAndroidPOS')}
                  />

                  <RadioBuilder
                    value="r3"
                    clickHandler={handleClick}
                    content={t('ContactForm_AppID_NowPrepay_VerifonePOS')}
                  />

                  <RadioBuilder
                    value="r6"
                    clickHandler={handleClick}
                    content={t('ContactForm_AppID_NowPrepay_MyeCash')}
                  />

                </div>
                <div className="nowprepay-app-div">

                  <RadioBuilder
                    value="r7"
                    clickHandler={handleClick}
                    content={t('ContactForm_AppID_NowPrepay_Other')}
                  />

                </div>
                <Form.Text
                  id="app_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_App_Validation_Error')}
                </Form.Text>
              </fieldset>
            </Form.Group>

            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Body_"
              required
            >
              <fieldset>
                <Form.Label as="legend" className="form-labels" id="aria-desc">
                  {t('ContactForm_General_Body_Label')}
                </Form.Label>
                <Form.Control
                  className="description-attr"
                  as="textarea"
                  aria-labelledby="aria-desc"
                  rows={8}
                  ref={bodyRef}
                  onBlur={() => {
                    setDescriptionValidateOnChange(true);
                    const curError = validateDescriptionBody(bodyRef);
                    if (curError !== undefined) {
                      document
                        .getElementsByClassName('description-attr')[0]
                        .classList.add('is-invalid');
                      showErrorFromList(curError);
                    } else {
                      if (
                        document
                          .getElementsByClassName('description-attr')[0]
                          .classList.contains('is-invalid')
                      ) {
                        document
                          .getElementsByClassName('description-attr')[0]
                          .classList.remove('is-invalid');
                      }
                      showErrorFromList(validateDescriptionBody(bodyRef));
                    }
                  }}
                  onChange={(e) => {
                    handleTextareaChange(e);
                    if (descriptionValidateOnChange) {
                      const curError = validateDescriptionBody(bodyRef);
                      if (curError !== undefined) {
                        document
                          .getElementsByClassName('description-attr')[0]
                          .classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else {
                        if (
                          document
                            .getElementsByClassName('description-attr')[0]
                            .classList.contains('is-invalid')
                        ) {
                          document
                            .getElementsByClassName('description-attr')[0]
                            .classList.remove('is-invalid');
                        }
                        showErrorFromList(validateDescriptionBody(bodyRef));
                      }
                    }
                  }}
                />
                <Form.Text
                  id="description_empty_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Body_Error')}
                </Form.Text>
                <Form.Text
                  id="description_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Body_Character_Length_Error')}
                </Form.Text>
              </fieldset>
            </Form.Group>
            <RelevantFAQ searchResults={searchResults} brand={brand} lang={lang} />

            <Button
              id="submit-button"
              className="nowprepay-button"
              type="submit"
              disabled={disable}
              size="lg"
              style={{ backgroundColor: '#2B4E7B' }}
            >
              {t('ContactForm_Submit_button')}
            </Button>
          </Form>
          <Alert className="mt-3 email-banner" show={process.env.REACT_APP_ENV === 'dev'}>
            On the test system, contact form emails will be sent to
            <strong> psidevstar@gmail.com</strong>
          </Alert>
        </div>
      </div>
    </>
  );
}

export default translate(ContactUsNowPrepay);
