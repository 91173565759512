import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';
import Home from './pages/home/Home';
import ScrollToTop from './ScrollToTop';
import WidgetHome from './components/widget/WidgetHome';
import { GenesysChat } from './components/widget/chat/GenesysChat';
import RouteOne from './pages/routing/RouteOne';
import RouteTwo from './pages/routing/RouteTwo';
import RouteThree from './pages/routing/RouteThree';
import RouteFour from './pages/routing/RouteFour';
import RouteNotFound from './pages/routing/RouteNotFound';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {/* GLOBAL ROUTES */}
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/widget/:brand?/:lang?/:type?/:id?" component={WidgetHome} />
          <Route exact path="/mobilechat/:brand/:lang/:theme?" component={GenesysChat} />
          <Route exact path="/:brand" component={RouteOne} />
          <Route exact path="/:brand/:lang" component={RouteTwo} />
          <Route exact path="/:brand/:lang/:type" component={RouteThree} />
          <Route exact path="/:brand/:lang?/:type?/:id?" component={RouteFour} />
          {/* TODO: Temporary handling for zenwallet. */}
          {/* Remove once zenwallet has been updated with new link to Cash Passport help link */}
          <Route exact path="/:brand/:lang?/:type?/:id?/:ignore" component={RouteFour} />
          <Route path="/:brand/:lang/*" component={RouteNotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
