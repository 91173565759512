import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { translate, t } from 'react-switch-lang';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { event as ampEvent, event, logAmpEvent } from '../../../helperFunctions/Amplitude';
import './contactusform.scss';
import './ContactUsMaple.scss';
import {
  hideContactError,
  SubmitContactUs,
} from '../../../helperFunctions/SubmitContactUs';
import { setScreenReaderAlert } from '../../../helperFunctions/Accessibility';
import { performSearch, searchByIDs } from '../../../components/algolia/AlgoliaUtils';
import RelevantFAQ from './RelevantFAQ';
import RadioBuilder from './RadioBuilder';
import mapleCardExample from '../../../images/maple/CardBack.png';

function ContactUsMaple({ widgetMode, removeAccount }) {
  const { brand, lang } = useSelector((state) => state);
  const emailRef = useRef();
  const bodyRef = useRef();
  const fullNameRef = useRef('');
  const cardNumberRef = useRef('');
  const phoneNumberRef = useRef('');
  const [appId, setAppId] = useState('');

  const [webActive, setWebActive] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);

  const [validateEmailOnChange, setValidateEmailOnChange] = useState(false);
  const [validateAppIdOnChange, setValidateAppIdOnChange] = useState(false);
  const [validateDescriptionOnChange, setValidateDescriptionOnChange] = useState(false);
  const [validateFullNameOnChange, setValidateFullNameOnChange] = useState(false);
  const [validateCardNumberOnChange, setValidateCardNumberOnChange] = useState(false);
  const [validatePhoneNumberOnChange, setValidatePhoneNumberOnChange] = useState(false);

  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [disable, setDisable] = React.useState(false);

  const [showContactForm, setShowContactForm] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestedArticleAlertRead, setSuggestedArticleAlertRead] = useState(false);

  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  const defaultFieldOptions = {
    pinIssues: false,
    lostStolenDamaged: false,
    linkIssues: false,
    transactionIssues: false,
    textArea: false,
  };
  const [showFields, setShowFields] = useState(defaultFieldOptions);
  const [category, setCategory] = useState();
  const [showExampleCard, setShowExampleCard] = useState(false);

  // null values indicate shouldn't validate on change yet
  const [formErrors, setFormErrors] = useState({
    email: null,
    appId: null,
    description: null,
    fullName: null,
    cardNumber: null,
    phoneNumber: null,
  });
  const [hardcodedArticles, setHardcodedArticles] = useState({
    pinIssues: [],
    lostStolenDamaged: [],
    transactionIssues: [],
  });

  function handleWebMobileClick(target) {
    setValidateAppIdOnChange(true);
    hideContactError('app_error_message');

    const isWeb = target === 'Maple website';
    setAppId(isWeb ? 'Web App - Canada Post Prepaid Card' : 'Mobile App - Canada Post Prepaid Card');
    setWebActive(isWeb);
    setMobileActive(!isWeb);
  }

  const handleShowFields = (option) => {
    setValidateEmailOnChange(false);
    setValidateAppIdOnChange(false);
    setValidateDescriptionOnChange(false);
    setValidateCardNumberOnChange(false);
    setValidateFullNameOnChange(false);
    setValidatePhoneNumberOnChange(false);

    hideContactError('category_error_message');

    switch (option) {
      case 'purchaseActivationIssues':
        setShowFields({
          ...defaultFieldOptions,
          textArea: true,
        });
        setCategory(t('ContactForm_Category_Maple_PurchaseActivationIssues'));
        break;
      case 'pinIssues':
        setShowFields({
          ...defaultFieldOptions,
          pinIssues: true,
        });
        setCategory(t('ContactForm_Category_Maple_PINIssues'));
        break;
      case 'lostStolenDamaged':
        setShowFields({
          ...defaultFieldOptions,
          lostStolenDamaged: true,
        });
        setCategory(t('ContactForm_Category_Maple_LostStolenDamaged'));
        break;
      case 'linkIssues':
        setShowFields({
          ...defaultFieldOptions,
          linkIssues: true,
          textArea: true,
        });
        setCategory(t('ContactForm_Category_Maple_LinkIssues'));
        break;
      case 'reloadIssues':
        setShowFields({
          ...defaultFieldOptions,
          textArea: true,
        });
        setCategory(t('ContactForm_Category_Maple_ReloadIssues'));
        break;
      case 'transactionIssues':
        setShowFields({
          ...defaultFieldOptions,
          transactionIssues: true,
        });
        setCategory(t('ContactForm_Category_Maple_TransactionIssues'));
        break;
      case 'accountDeletion':
        setShowFields({
          ...defaultFieldOptions,
          textArea: true,
        });
        setCategory(t('ContactForm_Category_All_AccountDeletion'));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    async function fetchArticles() {
      const pinIssues = await searchByIDs(['949', '951', '2727']);
      const lostStolenDamaged = await searchByIDs(['1011']);

      // transactionIssues go to sections, so they are hardcoded
      const transactionIssues = [
        {
          Content: [
            { Title: t('ContactForm_Maple_TransactionIssues_CardPurchases_Section'), Language: lang },
          ],
          objectID: '837',
          IsSection: true,
        },
        {
          Content: [
            { Title: t('ContactForm_Maple_TransactionIssues_DisputingTransactions_Section'), Language: lang },
          ],
          objectID: '841',
          IsSection: true,
        },
      ];

      setHardcodedArticles({ pinIssues, lostStolenDamaged, transactionIssues });
    }

    fetchArticles();
  }, [lang]);

  // Check if widget
  useEffect(() => {
    if (widgetMode) {
      document.getElementById('desktop-contact-us').style.padding = '0px';
    }
    if (removeAccount) {
      handleWebMobileClick('Maple mobile app');
      handleShowFields('accountDeletion');
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    async function updateSearchResults() {
      const results = await performSearch(searchTerm, brand, lang);
      if (results.length > 0) {
        setSuggestedArticleAlertRead(true);
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_Suggested_Article', null, lang));
      } else if (suggestedArticleAlertRead) {
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_No_Suggested_Article', null, lang));
      }
      setSearchResults(results);
    }
    const timer = setTimeout(updateSearchResults, 700);
    return () => { clearTimeout(timer); };
  }, [searchTerm, lang]);

  function validateEmpty(value) {
    if (value.trim().length === 0) return t('Validation_Required');
    return null;
  }

  function validatePhoneNumber(value) {
    if (!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)) return t('Validation_Type_Phone');
    return null;
  }

  function validateCardNumber(value) {
    if (!/^[0-9]+$/.test(value)) return t('Validation_Type_Number');
    if (value.length !== 12) return t('Validation_Length_Exact').replace('xLength', '12');
    return null;
  }

  function validateDescription(value) {
    if (value.length > 1500) return t('Validation_Length_Max').replace('xMax', '1500');
    return null;
  }

  function validateEmail(value) {
    if (value.length > 50) return t('Validation_Length_Max').replace('xMax', '50');
    if (!/^[\w!#$%&*+/=?`{|}~^-‘’']+(?:\.[\w!#$%&*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,32}$/.test(value)) return t('Validation_Type_Email');
    return null;
  }

  // use force param to skip onChange flags
  function validateFormFields(force) {
    const errors = {
      email: null,
      appId: null,
      description: null,
      fullName: null,
      cardNumber: null,
      phoneNumber: null,
    };
    if ((validateEmailOnChange || force) && emailRef.current) {
      const { value } = emailRef.current;
      errors.email = validateEmpty(value) || validateEmail(value);
    }
    if ((validateAppIdOnChange || force) && appId === '') errors.appId = t('ContactForm_General_Validation_Required');
    if ((validateDescriptionOnChange || force) && bodyRef.current) {
      const { value } = bodyRef.current;
      errors.description = validateEmpty(value) || validateDescription(value);
    }
    if ((validateFullNameOnChange || force) && fullNameRef.current) {
      const { value } = fullNameRef.current;
      errors.fullName = validateEmpty(value);
    }
    if ((validateCardNumberOnChange || force) && cardNumberRef.current) {
      const { value } = cardNumberRef.current;
      errors.cardNumber = validateEmpty(value) || validateCardNumber(value);
    }
    if ((validatePhoneNumberOnChange || force) && phoneNumberRef.current) {
      const { value } = phoneNumberRef.current;
      errors.phoneNumber = validateEmpty(value) || validatePhoneNumber(value);
    }
    setFormErrors(errors);
    return errors;
  }

  useEffect(() => {
    validateFormFields();
  }, [
    lang,
    appId,
    validateEmailOnChange,
    validateAppIdOnChange,
    validateDescriptionOnChange,
    validateFullNameOnChange,
    validateCardNumberOnChange,
    validatePhoneNumberOnChange,
  ]);

  function sendAPI() {
    const data = {
      BrandID: appId,
      CustomerEmail: emailRef.current.value,
      Language: lang,
      Category: category,
      PhoneNumber: phoneNumberRef.current.value,
      Name: fullNameRef?.current.value,
      CardProxy: cardNumberRef?.current.value,
      Body: bodyRef.current.value,
    };

    let sending = document.getElementById('submit-button');
    sending.innerHTML = t('ContactForm_Submitting');
    setDisable(true);

    SubmitContactUs(data).then(
      () => {
        setShow(false);
        setShowContactForm(false);
        setShowSuccessMessage(true);
        const focus = document.getElementById('mapleSuccessMessage');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },
      () => {
        setError(t('Error_Something_Went_Wrong'));
        setShow(true);
        sending = document.getElementById('submit-button');
        sending.innerHTML = t('ContactForm_Submit_button');
        setDisable(false);
        const focus = document.getElementById('error_message');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    );
  }

  function handleSubmit(e) {
    e.preventDefault();
    logAmpEvent(event.USER_CLICKED_SUBMIT);

    setValidateEmailOnChange(true);
    setValidateAppIdOnChange(true);
    setValidateDescriptionOnChange(true);
    setValidateCardNumberOnChange(true);
    setValidateFullNameOnChange(true);
    setValidatePhoneNumberOnChange(true);

    const errors = validateFormFields(true);

    if (!Object.values(errors).some((err) => err !== null)) {
      sendAPI();
      setScreenReaderAlert(t('ScreenReader_ContactForm_Success'));
    } else {
      logAmpEvent(ampEvent.USER_ERROR_CONTACT, { 'Error Type': formErrors });
      setScreenReaderAlert(t('ScreenReader_ContactForm_Error'));
    }
  }

  const handleTextareaChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      {show && (
        <Alert
          variant="danger"
          id="error_message"
          className="mt-3"
          onClose={() => {
            setShow(false);
            setError('');
          }}
          dismissible
        >
          <p style={{ marginBottom: 0 }}>{error}</p>
        </Alert>
      )}

      {showSuccessMessage && (
        <div id="mapleSuccessMessage">
          <h1>{t('ContactForm_Submitted')}</h1>
          <Link to={(widgetMode) ? `/widget/canadapost-prepaid/${lang}${isMobile}` : `/canadapost-prepaid/${lang}/categories`}>
            <Button
              className="error-go-home-btn maple-button"
            >
              {t('ReturnHome_Btn')}
            </Button>
          </Link>
        </div>
      )}

      {/* Start Form */}
      {showContactForm && (
        <div id="desktop-contact-us">
          <div id="skip-link-content">
            <h1>{t('General_Contact_Us')}</h1>
            <p
              className="contact-label-suggestion"
            >
              {t('ContactForm_Fields_Required')}
            </p>
            <Form
              noValidate
              onSubmit={handleSubmit}
            >

              <Form.Group
                className="form-groups"
                controlId="ContactForm_General_Email_"
                required
              >
                <fieldset>
                  <Form.Label as="legend" id="aria-email" className="form-labels">
                    {t('ContactForm_General_Email_Label')}
                  </Form.Label>
                  <Form.Text className="muted email-help">
                    {t('ContactForm_General_Email_Help')}
                  </Form.Text>
                  <Form.Control
                    aria-labelledby="aria-email aria-email-warn"
                    ref={emailRef}
                    className={formErrors.email ? 'is-invalid' : ''}
                    onBlur={() => setValidateEmailOnChange(true)}
                    onChange={() => {
                      if (validateEmailOnChange) validateFormFields();
                    }}
                  />
                  <Form.Text
                    className="maple-form-error-message"
                  >
                    {formErrors.email}
                  </Form.Text>
                </fieldset>
              </Form.Group>

              <Form.Group
                className="form-inline"
                controlId="ContactForm_AppID_"
                required
              >
                <fieldset className="maple-radio">
                  <Form.Label id="app_label" as="legend" className="form-labels">
                    {t('ContactForm_General_AppID_Label')}
                  </Form.Label>
                  <div className="web-mobile-btn maple-web-mobile-btn">
                    <Form.Check>
                      <Button
                        className={`clickable-btn ${mobileActive ? 'active-btn' : 'inactive-btn'
                        } maple-`}
                        id="contact-mobile-btn"
                        name="ContactForm_AppID_"
                        onClick={() => handleWebMobileClick('Maple mobile app')}
                      >
                        <i className="fal fa-mobile-alt btn-icon" />
                        {t('ContactForm_AppID_Mobile')}
                      </Button>
                    </Form.Check>

                    <Form.Check>
                      <Button
                        className={`clickable-btn ${webActive ? 'active-btn' : 'inactive-btn'
                        }`}
                        id="contact-web-btn"
                        name="ContactForm_AppID_"
                        onClick={() => handleWebMobileClick('Maple website')}
                      >
                        <i className="fal fa-window btn-icon" />
                        {t('ContactForm_AppID_Website')}
                      </Button>
                    </Form.Check>
                  </div>
                  <Form.Text
                    className="maple-form-error-message"
                  >
                    {formErrors.appId}
                  </Form.Text>

                </fieldset>
              </Form.Group>

              {/* Which app did you use? */}
              <fieldset className="maple-radio">
                <Form.Label id="category_label" as="legend" className="form-labels">
                  {t('ContactForm_General_Category_Label')}
                </Form.Label>

                <RadioBuilder
                  value="purchaseActivationIssues"
                  content={t('ContactForm_Category_Maple_PurchaseActivationIssues')}
                  clickHandler={handleShowFields}
                />
                <RadioBuilder
                  value="pinIssues"
                  content={t('ContactForm_Category_Maple_PINIssues')}
                  clickHandler={handleShowFields}
                />
                <RadioBuilder
                  value="lostStolenDamaged"
                  content={t('ContactForm_Category_Maple_LostStolenDamaged')}
                  clickHandler={handleShowFields}
                />
                <RadioBuilder
                  value="linkIssues"
                  content={t('ContactForm_Category_Maple_LinkIssues')}
                  clickHandler={handleShowFields}
                />
                <RadioBuilder
                  value="reloadIssues"
                  content={t('ContactForm_Category_Maple_ReloadIssues')}
                  clickHandler={handleShowFields}
                />
                <RadioBuilder
                  value="transactionIssues"
                  content={t('ContactForm_Category_Maple_TransactionIssues')}
                  clickHandler={handleShowFields}
                />
                <RadioBuilder
                  value="accountDeletion"
                  content={t('ContactForm_Category_All_AccountDeletion')}
                  clickHandler={handleShowFields}
                  selected={removeAccount}
                />

                <Form.Text
                  id="category_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Category_Validation_Error')}
                </Form.Text>
              </fieldset>

              {showFields.pinIssues && (
                <RelevantFAQ
                  searchResults={hardcodedArticles?.pinIssues}
                  brand={brand}
                  lang={lang}
                  isHardcoded
                  widgetMode={widgetMode}
                />
              )}

              {showFields.linkIssues && (
                <fieldset className="linkIssues-fieldset">
                  <label
                    htmlFor="fullName"
                    className="form-labels"
                  >
                    {t('ContactForm_Maple_FullName_Label')}
                  </label>
                  <input
                    type="text"
                    ref={fullNameRef}
                    id="fullName"
                    className={`form-control ${formErrors.fullName ? 'is-invalid' : ''}`}
                    onBlur={() => setValidateFullNameOnChange(true)}
                    onChange={() => {
                      if (validateCardNumberOnChange) validateFormFields();
                    }}
                  />
                  <div className="maple-form-error-message invalid-feedback">{formErrors.fullName}</div>

                  <label
                    htmlFor="cardNumber"
                    className="form-labels"
                  >
                    {t('ContactForm_Maple_CardNumber_Label')}
                  </label>
                  <input
                    type="text"
                    ref={cardNumberRef}
                    id="cardNumber"
                    className={`form-control ${formErrors.cardNumber ? 'is-invalid' : ''}`}
                    onBlur={() => setValidateCardNumberOnChange(true)}
                    onChange={() => {
                      if (validateCardNumberOnChange) validateFormFields();
                    }}
                    placeholder={t('ContactForm_Maple_CardNumber_Placeholder')}
                    maxLength={12}
                  />
                  <div className="maple-form-error-message invalid-feedback">{formErrors.cardNumber}</div>
                  <button
                    type="button"
                    className="maple-showExampleCard-btn"
                    onClick={() => setShowExampleCard((prev) => !prev)}
                  >
                    {showExampleCard ? t('ContactForm_Maple_CardNumber_Button_Hide') : t('ContactForm_Maple_CardNumber_Button')}
                  </button>
                  {showExampleCard && (
                    <img
                      src={mapleCardExample}
                      alt={t('ContactForm_Maple_CardNumber_ImageDescription')}
                      className="maple-exampleCard-img"
                    />
                  )}

                  <label
                    htmlFor="phoneNumber"
                    className="form-labels form-phoneNumber"
                  >
                    {t('ContactForm_Maple_PhoneNumber_Label')}
                  </label>
                  <p className="maple-phoneNumber-format">{t('ContactForm_Maple_PhoneNumber_Placeholder')}</p>
                  <input
                    type="text"
                    placeholder={t('ContactForm_Maple_PhoneNumber_Placeholder')}
                    ref={phoneNumberRef}
                    id="phoneNumber"
                    className={`form-control ${formErrors.phoneNumber ? 'is-invalid' : ''}`}
                    onBlur={() => setValidatePhoneNumberOnChange(true)}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^\d-]/g, '')
                        .replace(/^(.{12})(.*)$/g, '$1')
                        .replace(/^(\d{3})(\d{1})$/, '$1-$2')
                        .replace(/^(\d{3})-(\d{3})(\d{1})$/, '$1-$2-$3')
                        .replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
                      if (validatePhoneNumberOnChange) validateFormFields();
                    }}
                  />
                  <div className="maple-form-error-message invalid-feedback">{formErrors.phoneNumber}</div>

                </fieldset>
              )}

              {showFields.textArea && (
                <>
                  <Form.Group
                    className="form-groups"
                    controlId="ContactForm_General_Body_"
                    required
                  >
                    <fieldset>
                      <Form.Label as="legend" id="aria-desc" className="form-labels">
                        {t('ContactForm_General_Body_Label')}
                      </Form.Label>

                      <Form.Control
                        aria-labelledby="aria-desc"
                        as="textarea"
                        rows={8}
                        ref={bodyRef}
                        className={formErrors.description ? 'is-invalid' : ''}
                        onBlur={() => setValidateDescriptionOnChange(true)}
                        onChange={(e) => {
                          handleTextareaChange(e);
                          if (validateDescriptionOnChange) validateFormFields();
                        }}
                      />
                      <Form.Text
                        className="maple-form-error-message"
                      >
                        {formErrors.description}
                      </Form.Text>
                    </fieldset>
                  </Form.Group>
                  <RelevantFAQ
                    searchResults={searchResults}
                    brand={brand}
                    lang={lang}
                    widgetMode={widgetMode}
                  />
                </>
              )}

              {showFields.lostStolenDamaged && (
                <RelevantFAQ
                  searchResults={hardcodedArticles?.lostStolenDamaged}
                  brand={brand}
                  lang={lang}
                  isHardcoded
                  widgetMode={widgetMode}
                />
              )}

              {showFields.transactionIssues && (
                <RelevantFAQ
                  searchResults={hardcodedArticles?.transactionIssues}
                  brand={brand}
                  lang={lang}
                  isHardcoded
                  widgetMode={widgetMode}
                />
              )}

              {(showFields.textArea || showFields.linkIssues) && (
                <Button
                  id="submit-button"
                  className="maple-button"
                  type="submit"
                  disabled={disable}
                  size="lg"
                  style={{ backgroundColor: 'black !important' }}
                >
                  {t('ContactForm_Submit_button')}
                </Button>
              )}
            </Form>
            <Alert className="mt-3 email-banner" show={process.env.REACT_APP_ENV === 'dev'}>
              On the test system, contact form emails will be sent to
              <strong> psidevstar@gmail.com</strong>
            </Alert>
          </div>
        </div>
      )}
    </>
  );
}

export default translate(ContactUsMaple);
