import React from 'react';
import { Redirect } from 'react-router-dom';
import { checkBrandExists } from '../../configurations/Brands';
import NotFound from '../dynamicPages/NotFound';

function RouteOne({ match: { params: { brand } } }) {
  return checkBrandExists(brand.toLowerCase()) ?
    <Redirect to={`/${brand}/en/categories`} /> :
    <NotFound />;
}

export default RouteOne;
