import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLanguage } from 'react-switch-lang';
import { Redirect } from 'react-router-dom';
import { checkBrandExists, checkBrandLangExists } from '../../configurations/Brands';
import NotFound from '../dynamicPages/NotFound';
import Categories from '../dynamicPages/Categories';
import { actionSetBrand, actionSetLanguage } from '../../redux/Action';
import ContactSorter from '../dynamicPages/contact/ContactSorter';
import NowPrepayApp from '../dynamicPages/NowPrepayApp';
import MyeCash from '../dynamicPages/myEcashAd/MyeCash';
import { pageBuilder } from './RouterSharedFunction';
import Error from '../dynamicPages/Error';

function RouteThree({ match: { params: { brand, lang, type } } }) {
  const dispatch = useDispatch();
  brand = brand.toLowerCase();
  lang = lang.toLowerCase();
  type = type.toLowerCase();
  const [brandExist, setBrandExist] = useState(false);
  const [langExist, setLangExist] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (checkBrandExists(brand)) {
      // Check if language exist. If not, set to english
      if (checkBrandLangExists(brand, lang)) {
        setLanguage(lang);
        dispatch(actionSetBrand(brand));
        dispatch(actionSetLanguage(lang));
      } else {
        setLangExist(false);
      }
    } else {
      setRedirect(true);
    }
    setBrandExist(true);
  }, [brand, lang]);

  if (redirect) return <NotFound />;

  if (!langExist) {
    return <Redirect to={`/${brand}/en/`} />;
  }

  if (brandExist) {
    if (type === 'error') {
      return pageBuilder('error', Error, lang, brand);
    }

    if (type === 'apps' && brand === 'nowprepay') {
      return pageBuilder('nowprepayapp', NowPrepayApp, lang, brand);
    }

    if (type === 'myecash' && brand === 'nowprepay') {
      return pageBuilder('myecash', MyeCash, lang, brand);
    }

    switch (type) {
      case 'category':
      case 'categories':
        return pageBuilder('categories', Categories, lang, brand);
      case 'contactus':
      case 'contact':
        return pageBuilder('contactus', ContactSorter, lang, brand);
      default:
        return pageBuilder('notFound', NotFound, lang, brand);
    }
  }
  return null;
}

export default RouteThree;
