export default function googleScript() {
  if (!document.getElementById('google-script')) {
    const script = document.createElement('script');
    script.id = 'google-script';
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-P3GRY0B5MX';
    script.async = true;
    const script2 = document.createElement('script');
    script2.innerText = 'window.dataLayer = window.dataLayer || [];' +
            '  function gtag(){dataLayer.push(arguments);}' +
            '  gtag(\'js\', new Date());' +
            '' +
            '  gtag(\'config\', \'G-P3GRY0B5MX\');';
    document.body.appendChild(script);
    document.body.appendChild(script2);
  }
}
