import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { t } from 'react-switch-lang';
import './RelevantFAQ.scss';
import ReplaceTextWithElement from '../../../helperFunctions/ReplaceTextWithElement';

function ArticleCard({ category, section, content, isHardcoded }) {
  return (
    <Card.Body className="faq-card-body">
      <span className="faq-link-span">
        <span>
          {!isHardcoded && (
            <span className="faq-breadcrumb">
              <span>
                <ReplaceTextWithElement
                  text={category?.Title}
                  replaceWith={<i>Interac</i>}
                  replaceKey="Interac"
                />
                <i
                  className="faq-chevron far fa-xs fa-chevron-right"
                  aria-label={t('IconDescription_Breadcrumb_Chevron')}
                />
                <span>
                  <ReplaceTextWithElement
                    text={section?.Title}
                    replaceWith={<i>Interac</i>}
                    replaceKey="Interac"
                  />
                </span>
              </span>
            </span>
          )}
          <h3 className="faq-link-text">
            <ReplaceTextWithElement
              text={content?.Title}
              replaceWith={<i>Interac</i>}
              replaceKey="Interac"
            />
          </h3>
        </span>
        <i className="widget-breadcrumb__chevron fal fa-l fa-chevron-right" />
      </span>
    </Card.Body>
  );
}

// Component to display relevant FAQs
function RelevantFAQ({ searchResults, brand, lang, isHardcoded, widgetMode }) {
  if (searchResults.length > 0) {
    return (
      <Card className="relevant-articles-card">
        <Card.Header>
          <h2 className="relevant-articles-title">
            {isHardcoded ? (
              <>
                {t('Relevant_FAQ_Hardcoded_Title', lang)}
              </>
            ) : (
              <>
                <span aria-hidden>💡</span>
                {t('Relevant_FAQ_Title', lang)}
              </>
            )}
          </h2>
        </Card.Header>
        <ListGroup variant="flush">
          {searchResults.map((article) => {
            const category = article.Category?.find((item) => item.Language === lang);
            const section = article.Section?.find((item) => item.Language === lang);
            const content = article.Content?.find((item) => item.Language === lang);

            return (
              <ListGroup.Item className="faq-list-group" key={article.objectID}>
                <Card>
                  <a
                    className="faq-link"
                    href={`${widgetMode ? '/widget' : ''}/${brand}/${lang}/${article?.IsSection ? 'sections' : 'articles'}/${article.objectID}`}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={isHardcoded ? t('FAQ_Hardcoded_Link').replace('xArticleName', content?.Title) :
                      t('FAQ_Link', null, lang).replace('xCategory', category?.Title).replace('xSection', section?.Title).replace('xArticleName', content?.Title)}
                  >
                    <ArticleCard
                      category={category}
                      section={section}
                      content={content}
                      isHardcoded={isHardcoded}
                    />
                  </a>
                </Card>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        {isHardcoded && (
          <Card.Footer>
            <h2 className="relevant-articles-footer">{t('Relevant_FAQ_Footer_Title', lang)}</h2>
            <div className="relevant-articles-footer-content">
              <span>{t('Relevant_FAQ_Footer_Body', lang)}</span>
              <a
                href={`tel:${t('PeoplesTrust_PhoneNumber', lang)}`}
                aria-label={t('ContactForm_Footer_PhoneNumber_LinkDescription')}
              >
                {t('PeoplesTrust_PhoneNumber', lang)}
              </a>
            </div>
          </Card.Footer>
        )}
      </Card>
    );
  }
  return null;
}

export default RelevantFAQ;
