/* eslint-disable */
import Amplitude from 'amplitude-js';
// import { brandTitleGet, getAllBrands } from '../configurations/Brands';
import { isDevelopment } from './config';

const AMPLITUDE_SESSION_TIMEOUT = 20 * 60 * 1000;

function logToConsole(...args) {
  // eslint-disable-next-line no-console
  if (isDevelopment()) console.log(...args);
}

export function loadAmplitude() {
  const ampInstance = Amplitude.getInstance();
  ampInstance.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
    sessionTimeout: AMPLITUDE_SESSION_TIMEOUT,
  });
  logToConsole(ampInstance.getSessionId());
}

// function getParentUrl() {
//   const isInIframe = window.parent !== window;
//   let parentUrl = window.location.href;
//
//   if (isInIframe) {
//     parentUrl = document.referrer;
//   }
//
//   return parentUrl;
// }

export const event = {
  // User view page
  USER_VIEWED_CATEGORIES_SCREEN: 'USER_VIEWED_CATEGORIES_SCREEN',
  USER_VIEWED_CATEGORY: 'USER_VIEWED_CATEGORY',
  USER_VIEWED_ARTICLE: 'USER_VIEWED_ARTICLE',
  USER_VIEWED_SECTION: 'USER_VIEWED_SECTION',
  USER_VIEWED_CONTACT_SCREEN: 'USER_VIEWED_CONTACT_SCREEN',
  USER_VIEWED_ALL_BRANDS: 'USER_VIEWED_ALL_BRANDS',

  // User Rate article
  USER_RATED_ARTICLE: 'USER_RATED_ARTICLE',

  // User click navbar element
  USER_CLICKED_NAVBAR_LANGUAGE: 'USER_CLICKED_NAVBAR_LANGUAGE',
  USER_CLICKED_NAVBAR_HOME_ICON: 'USER_CLICKED_NAVBAR_HOME_ICON',
  USER_CLICKED_NAVBAR_LINK: 'USER_CLICKED_NAVBAR_LINK',
  USER_CLICKED_NAVBAR_CONTACT_US: 'USER_CLICKED_NAVBAR_CONTACT_US',
  USER_CLICKED_NAVBAR_SWITCH_LANGUAGE: 'USER_CLICKED_NAVBAR_SWITCH_LANGUAGE',
  USER_CLICKED_HAMBURGER: 'USER_CLICKED_HAMBURGER',

  // User click nav skip
  USER_CLICKED_SKIP_TO_CONTENT: 'USER_CLICKED_SKIP_TO_CONTENT',
  USER_CLICKED_SKIP_TO_SEARCH: 'USER_CLICKED_SKIP_TO_SEARCH',
  USER_CLICKED_SKIP_TO_SECTIONS_LIST: 'USER_CLICKED_SKIP_TO_SECTIONS_LIST',
  USER_CLICKED_SKIP_TO_FOOTER: 'USER_CLICKED_SKIP_TO_FOOTER',

  // User click footer
  USER_CLICKED_FOOTER_LINK: 'USER_CLICKED_FOOTER_LINK',

  // User click brand page
  USER_CLICKED_CATEGORY: 'USER_CLICKED_CATEGORY',

  // User click on home page
  USER_CLICKED_HOME_PAGE: 'USER_CLICKED_HOME_PAGE',

  // User click category list page
  USER_CLICKED_CATEGORIES_PAGE_SECTION_TITLE:
    'USER_CLICKED_CATEGORIES_PAGE_SECTION_TITLE',
  USER_CLICKED_SECTION_FROM_SECTIONS_LIST_LINK:
    'USER_CLICKED_SECTION_FROM_SECTIONS_LIST_LINK',
  USER_CLICKED_SECTION_PULLDOWN: 'USER_CLICKED_SECTION_PULLDOWN',
  USER_CLICKED_SECTION_PULLDOWN_LINK: 'USER_CLICKED_SECTION_PULLDOWN_LINK',
  USER_CLICKED_SECTION: 'USER_CLICKED_SECTION',
  USER_CLICKED_CATEGORIES_PAGE_ARTICLE_LINK:
    'USER_CLICKED_CATEGORIES_PAGE_ARTICLE_LINK',

  // User click session page
  USER_CLICKED_SECTION_PAGE_ARTICLE_LINK:
    'USER_CLICKED_SECTION_PAGE_ARTICLE_LINK',

  // User click Breadcrumb
  USER_CLICKED_BREADCRUMB_TO_BRAND_PAGE:
    'USER_CLICKED_BREADCRUMB_TO_BRAND_PAGE',
  USER_CLICKED_BREADCRUMB_TO_CATEGORY_PAGE:
    'USER_CLICKED_BREADCRUMB_TO_CATEGORY_PAGE',
  USER_CLICKED_BREADCRUMB_TO_SECTION_PAGE:
    'USER_CLICKED_BREADCRUMB_TO_SECTION_PAGE',

  // Genesys
  GENESYS_CHAT_STARTED: 'GENESYS_CHAT_STARTED',
  GENESYS_CHAT_ENDED: 'GENESYS_CHAT_ENDED',

  // Mobile
  USER_TAPPED_CATEGORIES_PAGE_CATEGORY_BTN:
    'USER_TAPPED_CATEGORIES_PAGE_CATEGORY_BTN',

  // Searchbar
  USER_CLICKED_SEARCH_BAR: 'USER_CLICKED_SEARCH_BAR',

  // Contact Us Web
  USER_CLICK_CONTACT_EMAIL: 'USER_CLICK_CONTACT_EMAIL',
  USER_SELECTED_APP_USED: 'USER_SELECTED_APP_USED',
  USER_SELECTED_HELP_CATEGORY: 'USER_SELECTED_HELP_CATEGORY',
  USER_SELECTED_PAYMENT_OPTION_USED: 'USER_SELECTED_PAYMENT_OPTION_USED',
  USER_CLICK_CONTACT_PULLDOWN: 'USER_CLICK_CONTACT_PULLDOWN',
  USER_CLICKED_SUBMIT: 'USER_CLICKED_SUBMIT',
  USER_CLICK_CONTACT_TEXTBOX: 'USER_CLICK_CONTACT_TEXTBOX',
  USER_SELECT_CONTACT_CARD_TYPE: 'USER_SELECT_CONTACT_CARD_TYPE',
  USER_ERROR_CONTACT: 'USER_ERROR_CONTACT',
  USER_CONTACT_US_SERVER_ERROR: 'USER_CONTACT_US_SERVER_ERROR',
  USER_CONTACT_US_SUBMIT_SUCCESS: 'USER_CONTACT_US_SUBMIT_SUCCESS',
  USER_RECEIVED_CONTACT_SERVER_ERROR: 'USER_RECEIVED_CONTACT_SERVER_ERROR',
  USER_CLICKED_CHECK_PAYMENT_STATUS: 'USER_CLICKED_CHECK_PAYMENT_STATUS',
  USER_CLICKED_GET_YOUR_RECEIPT: 'USER_CLICKED_GET_YOUR_RECEIPT',

  // Widget Specific
  USER_CLICK_EXTERNAL_LINK_WIDGET: 'USER_CLICK_EXTERNAL_LINK_WIDGET',
  USER_CLICK_CONTACT_US_FOOTER_WIDGET: 'USER_CLICK_CONTACT_US_FOOTER_WIDGET',
  USER_CLICK_WIDGET_HOME_BTN: 'USER_CLICK_WIDGET_HOME_BTN',
  USER_CLICK_WIDGET_CONTACT_US_NOT_FOOTER:
    'USER_CLICK_WIDGET_CONTACT_US_NOT_FOOTER',
  USER_CLICK_WIDGET_GENESYS_CHAT: 'USER_CLICK_WIDGET_GENESYS_CHAT',
  USER_CLICKED_WIDGET_MINIMIZE: 'USER_CLICKED_WIDGET_MINIMIZE',
  USER_CLICKED_WIDGET_BACK_BTN: 'USER_CLICKED_WIDGET_BACK_BTN',
  USER_DISABLED_LOCALSTORAGE: 'USER_DISABLED_LOCALSTORAGE',

  // MyeCash ad
  USER_VIEWED_MYECASH: 'USER_VIEWED_MYECASH',
  USER_CLICKED_GET_MYECASH: 'USER_CLICKED_GET_MYECASH',

  // Nowprepay Quick Link
  USER_VIEWED_QUICK_LINK: 'USER_VIEWED_QUICK_LINK',
  USER_OPEN_APP: 'USER_OPEN_APP',

  DB_ERROR: 'DB_ERROR',
};

export function logAmpEvent(thisEvent, option = null) {
  void (0);
  // let merged;
  // // const url = getParentUrl();
  // const url = window.location.href;
  // let thisBrand;
  // const allBrand = getAllBrands();
  // allBrand.forEach((brand) => {
  //   if (url.includes(brand)) {
  //     thisBrand = brand;
  //   }
  // });
  // const newOption = {
  //   Brand: brandTitleGet(thisBrand),
  //   Language: url.includes('/fr') ? 'fr' : 'en',
  //   Mode: url.includes('/widget/') ? 'widget' : 'desktop',
  // };
  //
  // if (url.includes('contactus')) {
  //   newOption.Form = 'Contact Us';
  // }
  // if (
  //   // eslint-disable-next-line no-use-before-define
  //   thisEvent === event.USER_CLICKED_WIDGET_MINIMIZE ||
  //   thisEvent === event.USER_CLICKED_WIDGET_BACK_BTN
  // ) {
  //   Amplitude.getInstance().logEvent(thisEvent);
  // } else {
  //   merged = { ...newOption, ...option };
  //   Amplitude.getInstance().logEvent(thisEvent, merged);
  // }
  // logToConsole(thisEvent, merged);
}

export function logAllBrand(thisEvent) {
  Amplitude.getInstance().logEvent(thisEvent);
  logToConsole(thisEvent);
}

export const opt = {
  LANG: 'language',
  BRAND: 'brand',
  CATEGORYID: 'Category ID',
  ARTICLEID: 'Article ID',
  SECTIONID: 'Section ID',
  HELPFUL: 'helpful',
  YES: 'yes',
  NO: 'no',
};

export default logAmpEvent;
