import React from 'react';
import { t, setLanguage } from 'react-switch-lang';
import { useDispatch } from 'react-redux';
import ContactUsTopmeup from '../../pages/dynamicPages/contact/ContactUsTopmeup';
import ContactUsZenwallet from '../../pages/dynamicPages/contact/ContactUsZenwallet';
import ContactUsPaySimply from '../../pages/dynamicPages/contact/ContactUsPaySimply';
import ContactUsNowPrepay from '../../pages/dynamicPages/contact/ContactUsNowPrepay';
import ContactUsDirectPay from '../../pages/dynamicPages/contact/ContactUsDirectPay';
import { actionSetLanguage } from '../../redux/Action';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import { brandTitleGet } from '../../configurations/Brands';
import ContactUsMaple from '../../pages/dynamicPages/contact/ContactUsMaple';

export default function WidgetSortContact({ brand, lang, removeAccount }) {
  const dispatch = useDispatch();
  dispatch(actionSetLanguage(lang));
  setLanguage(lang);

  DocumentTitle(
    t('PageTitle_Brand_Contact', null, lang).replace('xBrand', brandTitleGet(brand, lang)),
    null,
    lang
  );

  switch (brand) {
    case 'paysimply':
      return <ContactUsPaySimply widgetMode removeAccount={removeAccount} />;
    case 'zenwallet':
      return <ContactUsZenwallet widgetMode removeAccount={removeAccount} />;
    case 'nowprepay':
      return <ContactUsNowPrepay widgetMode />;
    case 'topmeup':
      return <ContactUsTopmeup widgetMode />;
    case 'directpay':
      return <ContactUsDirectPay widgetMode />;
    case 'canadapost-prepaid':
      return <ContactUsMaple widgetMode removeAccount={removeAccount} />;
    default:
      break;
  }
}
