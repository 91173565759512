import React from 'react';

export default function ReplaceTextWithElement({ text, replaceKey, replaceWith, className }) {
  if (text === undefined) return '';

  const startOfKey = text.indexOf(replaceKey);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (startOfKey === -1) return <>{text}</>;

  const firstStr = text.substr(0, startOfKey);
  const secondStr = text.substr(startOfKey + replaceKey.length);

  return (
    <span className={className}>
      {firstStr}
      {replaceWith}
      {secondStr}
    </span>
  );
}
