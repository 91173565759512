import React, { useEffect, useState } from 'react';
import { t, translate } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardDeck } from 'react-bootstrap';
import './ContactUsDirectPay.scss';
import './contactusform.scss';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { SubmitContactUs } from '../../../helperFunctions/SubmitContactUs';
import { event as ampEvent, logAmpEvent } from '../../../helperFunctions/Amplitude';
import { setScreenReaderAlert } from '../../../helperFunctions/Accessibility';
import ReplaceTextWithElement from '../../../helperFunctions/ReplaceTextWithElement';
import { performSearch } from '../../../components/algolia/AlgoliaUtils';
import RelevantFAQ from './RelevantFAQ';

function ContactUsDirectPay({ widgetMode }) {
  const { brand, lang } = useSelector((state) => state);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestedArticleAlertRead, setSuggestedArticleAlertRead] = useState(false);

  useEffect(() => {
    if (widgetMode) {
      document.getElementById('desktop-contact-us').style.padding = '0px';
    }
    logAmpEvent(ampEvent.USER_VIEWED_CONTACT_SCREEN); // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function updateSearchResults() {
      const results = await performSearch(searchTerm, brand, lang);
      if (results.length > 0) {
        setSuggestedArticleAlertRead(true);
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_Suggested_Article', null, lang));
      } else if (suggestedArticleAlertRead) {
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_No_Suggested_Article', null, lang));
      }
      setSearchResults(results);
    }
    const timer = setTimeout(updateSearchResults, 700);
    return () => { clearTimeout(timer); };
  }, [searchTerm, lang]);

  const [userType, setUserType] = useState(null);
  const [disable, setDisable] = useState(false);

  const [mEmailValOnChange, setMEmailValOnChange] = useState(false);
  const [mNameValOnChange, setMNameValOnChange] = useState(false);
  const [mCompanyValOnChange, setMCompanyValOnChange] = useState(false);
  const [mBTValOnChange, setMBTValOnChange] = useState(false);
  const [mPHValOnChange, setMPHValOnChange] = useState(false);
  const [mTextValOnChange, setMTextValOnChange] = useState(false);

  const [cEmailValOnChange, setCEmailValOnChange] = useState(false);
  const [cNameValOnChange, setCNameValOnChange] = useState(false);
  const [cPHValOnChange, setCPHValOnChange] = useState(false);
  const [cTextValOnChange, setCTextValOnChange] = useState(false);

  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  let errorList = [];

  function replaceSubmitBtnText() {
    setDisable(true);
    const submitBtn = document.getElementsByClassName('submit-button');
    submitBtn[0].innerText = t('ContactForm_Submitting');
    submitBtn[1].innerText = t('ContactForm_Submitting');
  }

  function restoreSubmitBtnText() {
    setDisable(false);
    const submitBtn = document.getElementsByClassName('submit-button');
    submitBtn[0].innerText = t('ContactForm_Submit_button');
    submitBtn[1].innerText = t('ContactForm_Submit_button');
  }

  function sendAPI() {
    let data;
    if (userType === 'merchant') {
      data = {
        BrandID: 'DirectPay',
        Category: 'Merchant',
        Name: $('.merchant-form #name').val(),
        CustomerEmail: $('.merchant-form #email').val(),
        Language: lang,
        Company: $('.merchant-form #company').val(),
        BusinessTitle: $('.merchant-form #business-title').val(),
        PhoneNumber: $('.merchant-form #phone-number').val(),
        Body: $('.merchant-form #help-textbox').val(),
      };
    } else {
      data = {
        BrandID: 'DirectPay',
        Category: 'Customer',
        Name: $('.customer-form #name').val(),
        CustomerEmail: $('.customer-form #email').val(),
        Language: lang,
        PhoneNumber: $('.customer-form #phone-number').val(),
        Body: $('.customer-form #help-textbox').val(),
      };
    }

    SubmitContactUs(data)
      .then(() => {
        $('.send-error').hide();
        $('.merchant-form').hide();
        $('.customer-form').hide();
        $('#dp-successMessage').show();
      })
      .catch(() => {
        $('.send-error').show();
        restoreSubmitBtnText();
      });
  }

  function handleMerchantCustomerSelector(option) {
    setUserType(option);
    switch (option) {
      case 'merchant':
        $('.dp-choose-container').fadeOut();
        setTimeout(() => {
          $('.merchant-form').fadeIn();
        }, 500);
        break;
      case 'customer':
        $('.dp-choose-container').fadeOut();
        setTimeout(() => {
          $('.customer-form').fadeIn();
        }, 500);
        break;
      default:
        return null;
    }
    return null;
  }

  function oopsIHaveToGoBack() {
    $('.send-error').fadeOut();
    $('.customer-form').fadeOut();
    $('.merchant-form').fadeOut();
    setTimeout(() => {
      $('.dp-choose-container').fadeIn();
    }, 500);
  }

  function getValueEventOrId(event) {
    return (
      ((typeof event) === 'object') ? event.target.value : $(event).val()
    );
  }

  function addRemoveValid(event, inValid) {
    if ((typeof event) === 'object') {
      if (inValid) {
        $(`.${userType}-form #${event.target.id}`).addClass('is-invalid');
      } else {
        $(`.${userType}-form #${event.target.id}`).removeClass('is-invalid');
      }
    } else if (inValid) {
      errorList.push(event);
      $(event).addClass('is-invalid');
    } else {
      $(event).removeClass('is-invalid');
    }
  }

  function changeErrorMessage(event, msg) {
    if ((typeof event) === 'object') {
      $(`.${userType}-form #${event.target.id} + *`).html(msg);
    } else {
      $(`${event} + *`).html(msg);
    }
  }
  function validatePhone(event, optional = false) {
    const phone = getValueEventOrId(event);
    const regex = /^(?=(\D*\d){10,}).{10,15}$/;
    if (optional && phone.trim().length === 0) {
      return true;
    }
    if (phone.trim().length === 0) {
      changeErrorMessage(event, t('ContactForm_General_Validation_Required'));
      addRemoveValid(event, true);
    } else {
      changeErrorMessage(event, t('ContactForm_Invalid_Phone_Number'));
      addRemoveValid(event, !regex.test(phone));
    }
    return true;
  }

  function validateEmpty(event, inputType = null) {
    const maxLength = inputType === 'description' ? 1500 : 50;
    const errorMessage =
      inputType === 'description' ?
        t('ContactForm_Body_Character_Length_Error') :
        t('ContactForm_Length_Error_50');
    const value = getValueEventOrId(event);
    if (value.trim().length === 0) {
      changeErrorMessage(event, t('ContactForm_General_Validation_Required'));
      addRemoveValid(event, true);
    } else {
      changeErrorMessage(event, errorMessage);
      addRemoveValid(event, value.trim().length > maxLength);
    }
  }

  function validateTextbox(event) {
    const maxLength = 1500;
    const errorMessage = t('ContactForm_Body_Character_Length_Error');
    const value = getValueEventOrId(event);
    if (value.trim().length === 0) {
      changeErrorMessage(event, t('ContactForm_General_Validation_Required'));
      addRemoveValid(event, true);
    } else {
      changeErrorMessage(event, errorMessage);
      addRemoveValid(event, value.trim().length > maxLength);
    }
  }

  const handleTextareaChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function validateEmail(event) {
    const email = getValueEventOrId(event);
    if (email.trim().length === 0) {
      $('.email-error').html(t('ContactForm_General_Validation_Required'));
      addRemoveValid(event, true);
    } else if (email.trim().length > 50) {
      changeErrorMessage(event, t('ContactForm_Length_Error_50'));
      addRemoveValid(event, true);
    } else {
      changeErrorMessage(event, t('ContactForm_General_Email_Validation'));
      addRemoveValid(event, !/^[\w!#$%&*+/=?`{|}~^-‘’']+(?:\.[\w!#$%&*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,32}$/.test(email));
    }
  }

  function validateMerchantOnSubmit() {
    setMEmailValOnChange(true);
    setMNameValOnChange(true);
    setMCompanyValOnChange(true);
    setMBTValOnChange(true);
    setMPHValOnChange(true);
    setMTextValOnChange(true);

    const nameField = '.merchant-form #name';
    const emailField = '.merchant-form #email';
    const phoneNumber = '.merchant-form #phone-number';
    const businessTitle = '.merchant-form #business-title';
    const companyField = '.merchant-form #company';
    const helpTextBox = '.merchant-form #help-textbox';

    validateEmpty(nameField);
    validateEmpty(companyField);
    validateEmpty(businessTitle);
    validatePhone(phoneNumber);
    validateEmail(emailField);
    validateTextbox(helpTextBox);
  }

  function validateCustomerOnSubmit() {
    setCNameValOnChange(true);
    setCEmailValOnChange(true);
    setCPHValOnChange(true);
    setCTextValOnChange(true);

    const nameField = '.customer-form #name';
    const phoneField = '.customer-form #phone-number';
    const emailField = '.customer-form #email';
    const helpTextBox = '.customer-form #help-textbox';

    validateEmpty(nameField);
    validatePhone(phoneField, true);
    validateEmail(emailField);
    validateTextbox(helpTextBox);
  }

  function scrollToError() {
    const firstError = errorList[0];
    $(firstError).focus();
  }

  function handleSubmit(e) {
    replaceSubmitBtnText();

    e.preventDefault();
    logAmpEvent(ampEvent.USER_CLICKED_SUBMIT);
    errorList = [];
    if (userType === 'merchant') {
      validateMerchantOnSubmit();
    } else if (userType === 'customer') {
      validateCustomerOnSubmit();
    } else {
      errorList.push('No user selected');
    }
    if (errorList.length === 0) {
      sendAPI();
      setScreenReaderAlert(t('ScreenReader_ContactForm_Success'));
    } else {
      logAmpEvent(ampEvent.USER_ERROR_CONTACT, { 'Error Type': errorList });
      restoreSubmitBtnText();
      setScreenReaderAlert(t('ScreenReader_ContactForm_Error'));
      scrollToError(errorList);
    }
  }

  function replaceTextKeyup() {
    let phone = $('#phone-number').val();
    phone = (!phone) ?
      '' :
      phone.replace(/[^\d-]/g, '')
        .replace(/^(.{12})(.*)$/g, '$1')
        .replace(/^(\d{3})(\d{1})$/, '$1-$2')
        .replace(/^(\d{3})-(\d{3})(\d{1})$/, '$1-$2-$3')
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
    $('#phone-number').val(phone);
  }

  return (
    <>
      <div
        className="send-error alert alert-danger alert-dismissible fade show"
        style={{ display: 'none' }}
        role="alert"
      >
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => {
            $('.send-error').fadeOut();
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {t('Error_Something_Went_Wrong')}
      </div>
      <div id="dp-successMessage">
        <h1>{t('ContactForm_Submitted')}</h1>
        <Link to={(widgetMode) ? `/widget/directpay/${lang}${isMobile}` : `/directpay/${lang}/categories`}>
          <Button className={`error-go-home-btn ${brand}-button`}>
            {t('ReturnHome_Btn')}
          </Button>
        </Link>
      </div>
      <CardDeck className="dp-choose-container">
        <Card
          className=""
          onClick={() => {
            handleMerchantCustomerSelector('merchant');
          }}
        >
          <Card.Body className="text-center">
            <i className="fas fa-5x fa-store dp-blue" />
          </Card.Body>
          <Card.Title>
            <div className="text-center">
              {t('ContactForm_I_am_A_Merchant')}
            </div>
          </Card.Title>
        </Card>

        <Card
          className=""
          onClick={() => {
            handleMerchantCustomerSelector('customer');
          }}
        >
          <Card.Body className="box-part text-center">
            <i className="fas fa-5x fa-users dp-green" />
          </Card.Body>
          <Card.Title>
            <div className="text-center">
              {t('ContactForm_I_am_A_Customer')}
            </div>
          </Card.Title>
        </Card>

      </CardDeck>
      <div id="desktop-contact-us">
        <div id="skip-link-content">
          <div className="merchant-form m-2" style={{ display: 'none' }}>

            {/* eslint-disable-next-line */}
            <button
              className="back-button"
              onClick={oopsIHaveToGoBack}
              title={t('DirectPay_Contact_Back_Select_User')}
            >
              <i className="fal fa-chevron-left dp-chevron" />
              {t('ContactForm_Back')}
            </button>

            <form className="dp-main-form-content">

              <div className="contact-us-heading">
                <h1 className="lbl-contact-us">{t('General_Contact_Us')}</h1>
                <p className="contact-label-suggestion">
                  {t('ContactForm_Fields_Required')}
                </p>
              </div>
              <div className="name form-group">
                <label htmlFor="name" id="lbl-name">
                  {t('ContactForm_Name')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  aria-labelledby="lbl-name"
                  onBlur={(e) => {
                    setMNameValOnChange(true);
                    validateEmpty(e);
                  }}
                  onChange={(e) => {
                    if (mNameValOnChange) {
                      validateEmpty(e);
                    }
                  }}
                />
                <div className="invalid-feedback">
                  {t('Validation_Required')}
                </div>
              </div>

              <div className="email-address form-group">
                <label htmlFor="email" id="lbl-email">
                  {t('ContactForm_General_Email_Label')}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-labelledby="lbl-email"
                  onBlur={(e) => {
                    setMEmailValOnChange(true);
                    validateEmail(e);
                  }}
                  onChange={(e) => {
                    if (mEmailValOnChange) {
                      validateEmail(e);
                    }
                  }}
                />
                <div className="invalid-feedback email-error">
                  {t('ContactForm_General_Email_Validation')}
                </div>
              </div>

              <div className="phone-number form-group">
                <label htmlFor="phone-number" id="lbl-phone-number">
                  {t('ContactForm_Phone_Number')}
                </label>
                <p className="contact-label-suggestion">999-999-9999</p>
                <input
                  type="tel"
                  className="form-control"
                  id="phone-number"
                  aria-labelledby="lbl-phone-number"
                  placeholder="999-999-9999"
                  onBlur={(e) => {
                    setMPHValOnChange(true);
                    validatePhone(e);
                  }}
                  onChange={(e) => {
                    if (mPHValOnChange) {
                      validatePhone(e);
                    }
                  }}
                  onKeyUp={replaceTextKeyup}
                />
                <div className="invalid-feedback">
                  {t('Validation_Required')}
                </div>
              </div>

              <div className="business-title form-group">
                <label htmlFor="business-title" id="lbl-business-title">
                  {t('ContactForm_Business_Title')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="business-title"
                  aria-labelledby="lbl-business-title"
                  onBlur={(e) => {
                    setMBTValOnChange(true);
                    validateEmpty(e);
                  }}
                  onChange={(e) => {
                    if (mBTValOnChange) {
                      validateEmpty(e);
                    }
                  }}
                />
                <div className="invalid-feedback">
                  {t('Validation_Required')}
                </div>
              </div>

              <div className="company form-group">
                <label htmlFor="company" id="lbl-company">
                  {t('ContactForm_Company')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="company"
                  aria-labelledby="lbl-company"
                  onBlur={(e) => {
                    setMCompanyValOnChange(true);
                    validateEmpty(e);
                  }}
                  onChange={(e) => {
                    if (mCompanyValOnChange) {
                      validateEmpty(e);
                    }
                  }}
                />
                <div className="invalid-feedback">
                  {t('Validation_Required')}
                </div>
              </div>

              <div className="help-textbox">
                <label htmlFor="help-textbox" id="lbl-help-textbox">
                  {t('ContactForm_How_Can_We_Help_You')}
                </label>
                <textarea
                  className="form-control"
                  id="help-textbox"
                  aria-labelledby="lbl-help-textbox"
                  rows="7"
                  onBlur={(e) => {
                    setMTextValOnChange(true);
                    validateTextbox(e);
                  }}
                  onChange={(e) => {
                    handleTextareaChange(e);
                    if (mTextValOnChange) {
                      validateTextbox(e);
                    }
                  }}
                />
                <div className="invalid-feedback">
                  {t('Validation_Required')}
                </div>
                <RelevantFAQ searchResults={searchResults} brand={brand} lang={lang} />
              </div>

              <button
                type="submit"
                className="btn btn-primary submit-button directpay-button"
                id="submit-button"
                disabled={disable}
                onClick={handleSubmit}
              >
                {t('ContactForm_Submit_button')}
              </button>
            </form>
            <Alert className="mt-3 email-banner" show={process.env.REACT_APP_ENV === 'dev'}>
              On the test system, contact form emails will be sent to
              <strong> psidevstar@gmail.com</strong>
            </Alert>
          </div>

          <div className="customer-form m-2" style={{ display: 'none' }}>
            <button
              type="button"
              className="back-button"
              title={t('DirectPay_Contact_Back_Select_User')}
              onClick={oopsIHaveToGoBack}
            >
              <i className="fal fa-chevron-left dp-chevron" />
              {t('ContactForm_Back')}
            </button>

            <form className="dp-main-form-content">

              <div className="contact-us-heading">
                <h1 className="lbl-contact-us">{t('General_Contact_Us')}</h1>
                <p className="contact-label-suggestion">
                  {t('ContactForm_Fields_Required')}
                </p>
              </div>

              <div className="name form-group">
                <label htmlFor="name" id="lbl-name">
                  {t('ContactForm_Name')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  aria-labelledby="lbl-name"
                  onBlur={(e) => {
                    setCNameValOnChange(true);
                    validateEmpty(e);
                  }}
                  onChange={(e) => {
                    if (cNameValOnChange) {
                      validateEmpty(e);
                    }
                  }}
                />
                <div className="invalid-feedback">
                  {t('Validation_Required')}
                </div>
              </div>

              <div className="email-address form-group">
                <label htmlFor="email" id="lbl-email">
                  {t('ContactForm_General_Email_Label')}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-labelledby="lbl-email"
                  onBlur={(e) => {
                    setCEmailValOnChange(true);
                    validateEmail(e);
                  }}
                  onChange={(e) => {
                    if (cEmailValOnChange) {
                      validateEmail(e);
                    }
                  }}
                />
                <div className="invalid-feedback email-error">
                  {t('Validation_Required')}
                </div>
              </div>

              <div className="phone-number form-group">
                <label htmlFor="phone-number" id="lbl-phone-number">
                  {t('ContactForm_Phone_Number')}
                  <span className="contact-label-suggestion ml-1">
                    (
                    {t('ContactForm_Optional')}
                    )
                  </span>
                </label>

                <p className="contact-label-suggestion">999 999 9999</p>
                <input
                  type="tel"
                  className="form-control"
                  id="phone-number"
                  aria-labelledby="lbl-phone-number"
                  placeholder="999 999 9999"
                  onBlur={(e) => {
                    setCPHValOnChange(true);
                    validatePhone(e, true);
                  }}
                  onChange={(e) => {
                    if (cPHValOnChange) {
                      validatePhone(e, true);
                    }
                  }}
                />
                <div className="invalid-feedback">
                  {t('Validation_Required')}
                </div>
              </div>

              <div className="help-textbox">
                <label htmlFor="help-textbox" id="lbl-help-textbox">
                  {t('ContactForm_How_Can_We_Help_You')}
                </label>
                <div className="contact-label-suggestion">
                  {t('Contact_DirectPay_Help_Title')}
                  <ul>
                    <li dangerouslySetInnerHTML={{ __html: t('Contact_DirectPay_Help_Point1').replaceAll('Interac', '<i>Interac</i>') }} />
                    <li>
                      <ReplaceTextWithElement
                        text={t('Contact_DirectPay_Help_Point2')}
                        replaceWith={<i>Interac</i>}
                        replaceKey="Interac"
                      />
                    </li>
                    <li>
                      <ReplaceTextWithElement
                        text={t('Contact_DirectPay_Help_Point3')}
                        replaceWith={<i>Interac</i>}
                        replaceKey="Interac"
                      />
                    </li>
                  </ul>
                </div>

                <textarea
                  className="form-control"
                  id="help-textbox"
                  aria-labelledby="lbl-help-textbox"
                  rows="7"
                  onBlur={(e) => {
                    setCTextValOnChange(true);
                    validateTextbox(e);
                  }}
                  onChange={(e) => {
                    handleTextareaChange(e);
                    if (cTextValOnChange) {
                      validateTextbox(e);
                    }
                  }}
                />
                <div className="invalid-feedback">
                  {t('Validation_Required')}
                </div>
                <RelevantFAQ searchResults={searchResults} brand={brand} lang={lang} />
              </div>

              <button
                type="submit"
                className="btn btn-primary submit-button directpay-button"
                id="submit-button"
                disabled={disable}
                onClick={handleSubmit}
              >
                {t('ContactForm_Submit_button')}
              </button>
            </form>
            <Alert className="mt-3 email-banner" show={process.env.REACT_APP_ENV === 'dev'}>
              On the test system, contact form emails will be sent to
              <strong> psidevstar@gmail.com</strong>
            </Alert>
          </div>
        </div>
      </div>
    </>
  );
}

export default translate(ContactUsDirectPay);
