import axios from 'axios';
import * as Sentry from '@sentry/react';
import { contactUrl } from './config';
import { event, logAmpEvent } from './Amplitude';

export function SubmitContactUs(data) {
  // replace ios apostrophe
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value && typeof value === 'string') {
      data[key] = value.replace(/[‘’]/g, "'");
    }
  });

  return new Promise((resolve, reject) => {
    axios
      .post(contactUrl(), JSON.stringify(data), { headers: { 'x-api-key': process.env.REACT_APP_LAMBDA_X_API_KEY } })
      .then((result) => {
        if (result.status === 200) {
          logAmpEvent(event.USER_CONTACT_US_SUBMIT_SUCCESS, { Brand: data.BrandID });
          resolve(200);
        } else {
          logAmpEvent(event.USER_CONTACT_US_SERVER_ERROR, {
            Brand: data.BrandID,
            Reason: result.data,
          });
          reject(result.data);
        }
      })
      .catch((err) => {
        logAmpEvent(event.USER_CONTACT_US_SERVER_ERROR, {
          Brand: data.BrandID,
          Reason: err.message,
        });
        Sentry.captureEvent(err);
        reject(err);
      });
  });
}

export function ShowContactError(id) {
  const errorMsg = document.getElementById(id);
  errorMsg.style.display = 'Block';

  const focus = errorMsg;
  focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

export function hideContactError(id) {
  try {
    const errorMsg = document.getElementById(id);
    errorMsg.style.display = 'None';
    errorMsg.removeAttribute('role');
  } catch (e) {
    return undefined;
  }
  return undefined;
}

function emailVal(email) {
  return /^[\w!#$%&*+/=?`{|}~^-‘’']+(?:\.[\w!#$%&*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,32}$/.test(email);
}

export function validatePhone(phoneRef) {
  hideContactError('phone-empty-error');
  hideContactError('phone-validation-error');
  const regex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  if (phoneRef.current.value.trim().length === 0) {
    return ('phone-empty-error');
  }
  if (!regex.test(phoneRef.current.value)) {
    return ('phone-validation-error');
  }
  return undefined;
}

export function validateEmail(emailRef) {
  hideContactError('email_empty_error_message');
  hideContactError('email_error_message');
  hideContactError('email_invalid');
  if (emailRef.current.value.trim().length === 0) {
    // ShowContactError("email_empty_error_message");
    return ('email_empty_error_message');
  } if (emailRef.current.value.length > 50) {
    return ('email_error_message');
  } if (!emailVal(emailRef.current.value)) {
    return ('email_invalid');
  }
  return undefined;
}

export function validateName(nameRef) {
  hideContactError('name-required');
  hideContactError('name-length-error');
  const name = nameRef.current.value;
  if (name.length === 0) {
    return ('name-required');
  } if (name.length > 50) {
    return ('name-length-error');
  }
  return undefined;
}

export function validateDescriptionBody(bodyref) {
  if (bodyref.current.value.trim().length === 0) {
    // ShowContactError("description_empty_error_message",true);
    return ('description_empty_error_message');
  }
  hideContactError('description_empty_error_message');

  if (bodyref.current.value.length > 1500) {
    // ShowContactError("description_error_message", true);
    return ('description_error_message');
  }
  hideContactError('description_error_message');
  return undefined;
}

export function showErrorFromList(errorList, widget) {
  if (errorList !== undefined) {
    if (typeof errorList === 'object') {
      errorList.reverse().forEach((err) => {
        ShowContactError(err, widget);
      });
    } else {
      ShowContactError(errorList, widget);
    }
  }
}

export function logErrorToAmp(errorList) {
  if ((typeof errorList) === 'object') {
    errorList.forEach((err) => {
      logAmpEvent(event.USER_ERROR_CONTACT, { 'Error Type': err });
    });
  } else {
    logAmpEvent(event.USER_ERROR_CONTACT, { 'Error Type': errorList });
  }
}
