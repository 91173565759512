import React, { useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { t } from 'react-switch-lang';

import { useSelector } from 'react-redux';
import $ from 'jquery';
import { makeLowerAndOnlyLetter } from '../../helperFunctions/LinkGenerator';
import Banner from '../../components/Banner/Banner';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import { brandTitleGet } from '../../configurations/Brands';
import './ListCategoryAndSection.scss';
import { DbConnect } from '../../helperFunctions/DbConnect';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';
import LoadingSpinner from '../../components/widget/widgetHelper/LoadingSpinner';

function sectionDescription(thisJson) {
  if (thisJson.Description !== '') {
    return (
      <div className="list-section-description">
        <ReplaceTextWithElement
          text={thisJson.Description}
          replaceWith={<i>Interac</i>}
          replaceKey="Interac"
        />
      </div>
    );
  }
  return true;
}

function pushArticles(thisJson) {
  const { brand, lang } = useSelector((state) => state);
  const myList = [];
  for (let i = 0; i < thisJson.Articles.length; i += 1) {
    if (thisJson.Articles[i].Title.length !== 0) {
      myList.push(
        <div key={`articleID: ${thisJson.Articles[i].ArticleID}`}>
          <li className="article-list-text">
            <Link
              key={`pushArticle${i}`}
              className="hyperlink-alt article-list"
              to={`/${brand}/${lang}/articles/${
                thisJson.Articles[i].ArticleID
              }-${makeLowerAndOnlyLetter(thisJson.Articles[i].Title)}`}
              onClick={() => {
                logAmpEvent(event.USER_CLICKED_CATEGORIES_PAGE_ARTICLE_LINK, {
                  'Article ID': thisJson.Articles[i].ArticleID,
                });
              }}
            >
              {' '}
              <ReplaceTextWithElement
                text={thisJson.Articles[i].Title}
                replaceWith={<i>Interac</i>}
                replaceKey="Interac"
              />
              {' '}
            </Link>
          </li>
          <div className="flex-whitespace" />
        </div>
      );
    }
  }
  return myList;
}

// Create creates a JSX for section and article and returns a list
function AddSectionArticleTogetherHelper(thisJson) {
  const { brand, lang } = useSelector((state) => state);
  const myList = [];
  if (thisJson.Articles.length > 0) {
    myList.push(
      <div key="sectionAndArticle">
        <h2 className="h4 section-title-container">
          <Link
            className="section-title hyperlink-alt"
            to={`/${brand}/${lang}/sections/${
              thisJson.SectionID
            }-${makeLowerAndOnlyLetter(thisJson.Title)}`}
            onClick={() => {
              logAmpEvent(event.USER_CLICKED_SECTION, {
                'Section ID': thisJson.SectionID,
              });
            }}
          >
            <ReplaceTextWithElement
              text={thisJson.Title}
              replaceWith={<i>Interac</i>}
              replaceKey="Interac"
            />
          </Link>
        </h2>
        {sectionDescription(thisJson)}
        <div
          className="flex-container"
          aria-label={`List of Articles from ${thisJson.Title}`}
        >
          <ul>
            <div>
              {pushArticles(thisJson)}
              {' '}
            </div>
          </ul>
        </div>
      </div>
    );
  }
  return myList;
}

// filter articles into sections
function MapSectionAndArticle(element) {
  const listOfSections = element.Sections;
  const togetherList = [];
  for (let i = 0; i < listOfSections.length; i += 1) {
    togetherList.push(AddSectionArticleTogetherHelper(listOfSections[[i]]));
  }
  // return listOfSections.map(AddSectionArticleTogetherHelper)
  return togetherList;
}

// THIS IS IF THE SECTIONS RETURNED IS IN A LIST FORMAT
function MapSections(dataFromDatabase, typeOfObject) {
  /* Function takes in a JSON object and returns a list of JSX.
   * @param {JSON} dataFromDatabase: JSON Object from database
   * @param {JSX function} typeOfObject: The type of JSX object to create. */
  // convert object to list
  const listOfSections = dataFromDatabase.Sections;
  const JSXList = [];
  for (let i = 0; i < listOfSections.length; i += 1) {
    JSXList.push(typeOfObject(listOfSections[[i]]));
  }
  return JSXList;
}

function CreateLink(item) {
  const { Articles, Title, SectionID } = item;
  const { brand, lang } = useSelector((state) => state);
  if (Articles.length > 0) {
    return (
      <div key={Title} className="section-link">
        <Link
          to={`/${brand}/${lang}/sections/${
            SectionID
          }-${makeLowerAndOnlyLetter(Title)}`}
          aria-label={Title}
          className="hyperlink-alt"
          onClick={() => {
            logAmpEvent(event.USER_CLICKED_SECTION_FROM_SECTIONS_LIST_LINK, {
              SectionID,
            });
          }}
        >
          <ReplaceTextWithElement
            text={Title}
            replaceWith={<i>Interac</i>}
            replaceKey="Interac"
          />
        </Link>
      </div>
    );
  }
}

function CreatePullDownObject(item) {
  const { Articles, Title, SectionID } = item;
  const { brand, lang } = useSelector((state) => state);
  if (Articles.length > 0) {
    // mobile pulldown
    return (
      <Link
        className="custom-dropdown-item"
        key={Title}
        to={`/${brand}/${lang}/sections/${SectionID}-${makeLowerAndOnlyLetter(Title)}`}
        onClick={() => {
          logAmpEvent(event.USER_CLICKED_SECTION_PULLDOWN_LINK, {
            SectionID,
          });
        }}
      >
        <ReplaceTextWithElement
          text={Title}
          replaceWith={<i>Interac</i>}
          replaceKey="Interac"
        />
      </Link>
    );
  }
}

function LayoutStyle(props) {
  const { content } = props;
  return (
    <div className="horizontal-layout">
      <div id="sections-content" className="left-layout">
        {/* renders desktop view */}
        <div className="section-in-category-list">
          <div
            className="section-in-category-heading"
            id="section-category-text"
          >
            <h3 className="h5">{t('Sections')}</h3>
          </div>
          {MapSections(content, CreateLink)}
        </div>
      </div>

      <div id="skip-link-content" className="right-layout">
        {/* Renders mobile view */}
        <div id="section-pull-down" aria-label="dropdown menu with list of available categories">
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" className="dropdown-button" id="dropdown">
              {t('Sections')}
            </Dropdown.Toggle>
            <Dropdown.Menu className="super-colors">
              {MapSections(content, CreatePullDownObject)}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="flex-container">
          <h1 id="category-title">
            <ReplaceTextWithElement
              text={content.Title}
              replaceWith={<i>Interac</i>}
              replaceKey="Interac"
            />
          </h1>
          {MapSectionAndArticle(content)}
        </div>
      </div>
    </div>
  );
}

function ListCategoryAndSection(props) {
  const { error, loading, sections, notFound, redirect, id } = props;
  const { brand, lang } = useSelector((state) => state);

  useEffect(() => {
    logAmpEvent(event.USER_VIEWED_CATEGORY, { 'Category ID': id });
  }, []);

  useEffect(() => {
    try {
      $('.ais-SearchBox-submit').attr('tabindex', -1);
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }, [loading]);

  if (redirect) {
    window.location.href = `${window.location.origin}/${brand}/en/categories/${id}`;
  }

  if (error) {
    return <Redirect to={`/${brand}/${lang}/error`} />;
  }
  if (notFound) {
    return <Redirect to={`/${brand}/${lang}/notFound`} />;
  }

  if (loading) {
    return (
      <>
        <Banner lang={lang} />
        <div id="skip-link-content">
          <LoadingSpinner />
        </div>
      </>
    );
  }
  DocumentTitle(
    t('PageTitle_Dynamic_Title')
      .replace('xBrand', brandTitleGet(brand, lang))
      .replace('xName', sections.Title)
  );
  return (
    <>
      <Banner lang={lang} />
      <div>
        <Breadcrumb
          categoryID={id}
          categoryName={sections.Title}
          lang={lang}
        />

        <LayoutStyle id={id} content={sections} />
      </div>
    </>
  );
}

export default DbConnect(ListCategoryAndSection, 'listCategoryAndSection');
