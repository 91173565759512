import algoliasearch from 'algoliasearch';
import { getSqlBrand } from '../../configurations/Brands';

const searchClient = algoliasearch('56TMYO60WS', process.env.REACT_APP_ALGOLIA_API_KEY);
const index = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

export async function performSearch(searchTerm, brand, lang) {
  try {
    if (searchTerm.trim() !== '') {
      const sqlBrand = getSqlBrand(brand);

      const { hits } = await index.search(searchTerm, {
        facetFilters: [`Brand:${sqlBrand}`],
      });
      let searchResults = hits.slice(0, (hits.length > 5) ? 5 : hits.length);

      // Removes any articles with missing category, content, section
      searchResults = searchResults.filter((article) => {
        if (article.Category.find((item) => item.Language === lang) == null) return false;
        if (article.Content.find((item) => item.Language === lang) == null) return false;
        if (article.Section.find((item) => item.Language === lang) == null) return false;
        return true;
      });

      return searchResults;
    }
    return [];
  } catch (error) {
    return [];
  }
}

export async function searchByIDs(ids) {
  if (ids.length === 0) return [];

  const articlePromises = ids.map((id) => index.getObject(id));
  const articles = await Promise.all(articlePromises);
  return articles;
}
