import React from 'react';
import { Button } from 'react-bootstrap';
import './WidgetFooter.scss';
import { Link } from 'react-router-dom';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';

const contactLang = {
  en: 'Contact Us',
  fr: 'Nous contacter',
};

function WidgetFooter(props) {
  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  const { isPageContact, brand, lang } = props;
  const hideStyle = {
    visibility: 'visible',
  };

  if (isPageContact) {
    hideStyle.visibility = 'hidden';
  }

  if (brand !== undefined && brand !== 'canadapost-singleloadprepaid' && lang !== undefined) {
    return (
      <div id="widget-footer-container" style={hideStyle}>
        <div className="widget-contact-container">
          <Link
            tabIndex="-1"
            to={`/widget/${brand}/${lang}/contactselect${isMobile}`}
            onClick={() => {
              logAmpEvent(event.USER_CLICK_CONTACT_US_FOOTER_WIDGET);
            }}

          >
            <Button id="widget-contact-us-btn" variant="dark">
              {contactLang[lang]}
            </Button>
          </Link>
        </div>
      </div>
    );
  }
  return null;
}

export default WidgetFooter;
