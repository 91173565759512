import React, { useState } from 'react';
import { InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { t } from 'react-switch-lang';
import { brandTitleGet } from '../../../configurations/Brands';

import {
  ConfigureBrand,
  searchClient,
  indexNameDevelopment,
  CustomHits,
  CustomResults,
  useCollapsibleSearchResult,
} from '../../algolia';
import './AlgoliaSearch.scss';

function Search({ brand, mini, lang }) {
  const [searchState, setSearchState] = useState({});
  useCollapsibleSearchResult(true);

  return (
    <div className="widget-ais-InstantSearch">
      <InstantSearch
        indexName={indexNameDevelopment}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={setSearchState}
      >
        <ConfigureBrand brand={brand} lang={lang} />
        <SearchBox
          inputId="widgetAlgoliaSearchBox"
          className={`widget-searchBox ${mini ? 'bordered' : ''}`}
          searchAsYouType
          showLoadingIndicator
          translations={{
            placeholder: `${t('Search_Placeholder', null, lang)}`.replace('xBrand', brandTitleGet(brand, lang)),
            submitTitle: t('IconDescription_AlgoliaSearchTitle', null, lang),
          }}
        />
        <CustomHits
          lang={lang}
          isWidget
          onResultClick={() => setSearchState({
            ...searchState,
            query: '',
          })}
        />
        <CustomResults lang={lang} />
      </InstantSearch>
    </div>
  );
}
export default Search;
