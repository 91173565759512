export function makeLowerAndOnlyLetter(text) {
  return text.toLowerCase().substring(0, 50)
    .replace(/[^a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\-*'"\s]/g, '')
    .replace(/\s\s/g, ' ')
    .replace(/\s/g, '-');
}

// takes the text after the second "/" and replaces it with language
export function replaceLinkLanguage(lang) {
  const originalURL = window.location.pathname;
  const re = /\/([a-zA-Z0-9-])+/g;
  const regexText = originalURL.match(re);
  let keepURL = '';
  for (let i = 0; i < regexText.length; i += 1) {
    if (i === 1) {
      regexText[i] = `/${lang}`;
    } else if (regexText[i] === 'undefined') {
      regexText[i] = '';
    }
    keepURL += regexText[i];
  }
  return keepURL;
}
