import React from 'react';
import { t, translate } from 'react-switch-lang';
import './404AndErrorCommon.scss';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Banner from '../../components/Banner/Banner';
import psImg from '../../images/paymentsourcelogo_colour-1.png';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';

function PageLayout(brand, lang) {
  return (
    <div id="error-container">
      <h1 className="xxl-font">
        {t('Error_404')}
        {' '}
      </h1>
      <Link to={brand === 'default' ? '/' : `/${brand}/${lang}/categories`}>
        <Button className={`error-go-home-btn ${brand}-button`}>
          {t('ReturnHome_Btn')}
        </Button>
      </Link>
    </div>
  );
}

/** Not found page
 * @param {String} [brand] - Current Brand.
 * @param {String} [lang] - Current Language.
 * @returns JSX
 * */
function NotFound({ brand = 'default', lang = 'en' }) {
  DocumentTitle(t('DocTitle_PageNotFound', lang, null));

  function shouldShowBanner() {
    return brand === 'default' ? (
      <>
        <nav
          className="navbar navbar-expand-lg"
          style={{
            backgroundColor: '#232222',
            height: 40,
            marginBottom: '20px',
          }}
        />
        <div className="d-flex justify-content-center">
          <img className="ps-logo-error" src={psImg} alt="PaymentSource Logo" />
        </div>
      </>
    ) : (
      <Banner lang={lang} />
    );
  }

  return (
    <>
      {shouldShowBanner()}
      <div id="skip-link-content">{PageLayout(brand, lang)}</div>
    </>
  );
}

export default translate(NotFound);
