import React from 'react';
import { translate, t } from 'react-switch-lang';
import { moveFocusTo, moveFocusToClass } from '../../helperFunctions/Accessibility';
import './SkipToNav.css';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';

function SkipToNav({ pageName }) {
  const skipToSearch = () => {
    logAmpEvent(event.USER_CLICKED_SKIP_TO_SEARCH);
    moveFocusToClass('ais-SearchBox-input');
  };
  const skipToSections = () => {
    logAmpEvent(event.USER_CLICKED_SKIP_TO_SECTIONS_LIST);
    moveFocusTo('sections-content');
  };
  const skipToContent = () => {
    logAmpEvent(event.USER_CLICKED_SKIP_TO_CONTENT);
    moveFocusTo('skip-link-content');
  };
  const skipToFooter = () => {
    logAmpEvent(event.USER_CLICKED_SKIP_TO_FOOTER);
    moveFocusTo('footer-content');
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function NavLogic() {
    switch (pageName) {
      case 'contactus':
      case 'myecash':
      case 'nowprepayapp':
        return (
          <>
            <SkipToButton onClick={skipToContent} textKey="ButtonDescription_Skip_To_Content" />
            <SkipToButton onClick={skipToFooter} textKey="ButtonDescription_Skip_To_Footer" />
          </>
        );
      case 'section':
      case 'categories':
      case 'article':
        return (
          <>
            <SkipToButton onClick={skipToSearch} textKey="ButtonDescription_Skip_To_Search" />
            <SkipToButton onClick={skipToContent} textKey="ButtonDescription_Skip_To_Content" />
            <SkipToButton onClick={skipToFooter} textKey="ButtonDescription_Skip_To_Footer" />
          </>
        );
      case 'listCategory':
        return (
          <>
            <SkipToButton onClick={skipToSearch} textKey="ButtonDescription_Skip_To_Search" />
            <SkipToButton onClick={skipToSections} textKey="ButtonDescription_Skip_To_Sections" />
            <SkipToButton onClick={skipToContent} textKey="ButtonDescription_Skip_To_Content" />
            <SkipToButton onClick={skipToFooter} textKey="ButtonDescription_Skip_To_Footer" />
          </>
        );
      default:
        return (
          <>
            <SkipToButton onClick={skipToContent} textKey="ButtonDescription_Skip_To_Content" />
            <SkipToButton onClick={skipToFooter} textKey="ButtonDescription_Skip_To_Footer" />
          </>
        );
    }
  }
  return <SkipCont>{NavLogic()}</SkipCont>;
}

export function SkipToButton({ textKey, onClick, ...args }) {
  return (
    <div className="skip-sub-cont">
      <button type="button" onClick={onClick} className="skip-link" {...args}>
        {t(textKey)}
      </button>
    </div>
  );
}

function SkipCont({ children }) {
  return (
    <div
      id="skip-content"
      className="skip-link-cont"
      aria-label={t('LinkDescription_SkipNav')}
    >
      <div className="load-skip-link-cont">{children}</div>
    </div>
  );
}

export default translate(SkipToNav);
