import React from 'react';
import { Link } from 'react-router-dom';
import './WidgetBrands.scss';
import { Card } from 'react-bootstrap';
import { brandConfig } from '../../configurations/Brands';

function WidgetBrands() {
  function renderBrands() {
    const keys = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in brandConfig) {
      keys.push(key);
    }

    return keys.map((key) => (
      <Card key={key} className="widget-card">
        <Link to={`/widget/${key}`}>
          {' '}
          <Card.Img
            className="widget-card-img"
            src={brandConfig[key].widgetMode.image}
          />
          {' '}
        </Link>
      </Card>
    ));
  }

  return (
    <div id="category-flex-container">
      {renderBrands()}
    </div>
  );
}

export default WidgetBrands;
