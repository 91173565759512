import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { t } from 'react-switch-lang';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import LoadingSpinner from './widgetHelper/LoadingSpinner';
import './WidgetCommon.scss';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { brandTitleGet } from '../../configurations/Brands';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import WidgetError from './WidgetError';
import { DbConnect } from '../../helperFunctions/DbConnect';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';
import WidgetNotFound from './WidgetNotFound';

function WidgetCategories(props) {
  const { error, loading, notFound, sections, setFilteringByText } = props;
  const { brand, lang } = useSelector((state) => state);

  useEffect(() => {
    if (!loading && !notFound) {
      setFilteringByText((currentText) => (currentText || t('Widget_Search_Filtering_By_Text', null, lang) + sections.Title));
    }
  }, [loading, notFound]);

  useEffect(() => {
    try {
      $('.ais-SearchBox-submit').attr('tabindex', -1);
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }, [loading]);

  function populateSections() {
    const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');
    const itemList = sections.Sections;
    const jsxObjectList = [];
    itemList.forEach((res) => {
      if (res.Articles.length > 0) {
        jsxObjectList.push(
          <Link
            className="widget-main-link"
            key={res.SectionID}
            to={`/widget/${brand}/${lang}/section/${res.SectionID}${isMobile}`}
            onClick={() => {
              logAmpEvent(event.USER_CLICKED_SECTION, {
                'Section ID': res.SectionID,
              });
            }}
          >
            <Card className="widget-main-card">
              <ReplaceTextWithElement
                text={res.Title}
                replaceWith={<i>Interac</i>}
                replaceKey="Interac"
              />
            </Card>
          </Link>
        );
      }
    });
    return jsxObjectList;
  }
  if (notFound) return <WidgetNotFound brand={brand} lang={lang} />;
  if (error) return <WidgetError />;
  if (loading) return <LoadingSpinner />;

  DocumentTitle(
    t('PageTitle_Dynamic_Title', null, lang)
      .replace('xBrand', brandTitleGet(brand, lang))
      .replace('xName', sections.Title)
  );

  // Append category title to filtering note
  return (
    <div className="widget-main-flex-container">
      <div className="title-text">
        <ReplaceTextWithElement
          text={sections.Title}
          replaceWith={<i>Interac</i>}
          replaceKey="Interac"
        />
      </div>
      {populateSections()}
    </div>
  );
}

export default DbConnect(WidgetCategories, 'listCategoryAndSection');
