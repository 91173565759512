import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { t, translate } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import './contactusform.scss';
import './ContactUsPaySimply.scss';
import { useSelector } from 'react-redux';
import {
  hideContactError,
  SubmitContactUs,
  validateEmail,
  validateDescriptionBody,
  showErrorFromList,
} from '../../../helperFunctions/SubmitContactUs';
import { event as ampEvent, event, logAmpEvent } from '../../../helperFunctions/Amplitude';
import { setScreenReaderAlert } from '../../../helperFunctions/Accessibility';
import RadioBuilder from './RadioBuilder';
import { performSearch } from '../../../components/algolia/AlgoliaUtils';
import RelevantFAQ from './RelevantFAQ';

function ContactUsPaySimply({ widgetMode, removeAccount }) {
  const { brand, lang } = useSelector((state) => state);
  const emailRef = useRef();
  const bodyref = useRef();
  const [appId, setAppId] = useState('');
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState('');
  const [disable, setDisable] = useState(false);
  const [paymentOption, setPaymentOption] = useState('');
  const [isPayment, setIsPayment] = useState(false);

  const defaultFieldOptions = {
    textArea: false,
    dropdown: false,
    paymentStatus: false,
    resendReceipt: false,
  };
  const [showFields, setShowFields] = useState(defaultFieldOptions);

  const [webActive, setWebActive] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);

  const [shouldValidateEmailOnChange, setEmailValidateOnChange] = useState(false);
  const [descriptionValidateOnChange, setDescriptionValidateOnChange] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestedArticleAlertRead, setSuggestedArticleAlertRead] = useState(false);

  const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

  function handleWebMobileClick(target) {
    hideContactError('app_error_message');
    const isWebsite = target === 'PaySimply website';

    setAppId(target);
    setWebActive(isWebsite);
    setMobileActive(!isWebsite);
  }

  function handleShowFields(option) {
    hideContactError('category_error_message');
    setIsPayment(false);
    setCategory(option);
    switch (option) {
      case 'Payment Verification':
        setIsPayment(true);
        setShowFields({
          ...defaultFieldOptions,
          paymentStatus: true,
        });
        break;
      case 'Payment Issue':
        setIsPayment(true);
        setShowFields({
          ...defaultFieldOptions,
          textArea: true,
          dropdown: true,
        });
        break;
      case 'Missing Receipt':
        setShowFields({
          ...defaultFieldOptions,
          resendReceipt: true,
        });
        break;
      case 'Other':
        setShowFields({
          ...defaultFieldOptions,
          textArea: true,
        });
        break;
      case 'Data or account deletion request':
        setShowFields({
          ...defaultFieldOptions,
          textArea: true,
        });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (widgetMode) {
      document.getElementById('desktop-contact-us').style.padding = '0px';
    }
    if (removeAccount) {
      handleWebMobileClick('PaySimply mobile app');
      handleShowFields('Data or account deletion request');
    }
  }, []);

  useEffect(() => {
    if (category !== '') {
      logAmpEvent(event.USER_SELECTED_HELP_CATEGORY, { 'Help Category': category });
    }
  }, [category]);

  useEffect(() => {
    async function updateSearchResults() {
      const results = await performSearch(searchTerm, brand, lang);
      if (results.length > 0) {
        setSuggestedArticleAlertRead(true);
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_Suggested_Article', null, lang));
      } else if (suggestedArticleAlertRead) {
        setScreenReaderAlert(t('FAQ_ScreenReaderAlert_No_Suggested_Article', null, lang));
      }
      setSearchResults(results);
    }
    const timer = setTimeout(updateSearchResults, 700);
    return () => { clearTimeout(timer); };
  }, [searchTerm, lang]);

  function sendAPI() {
    let data = {};
    if (isPayment) {
      data = {
        BrandID: appId,
        CustomerEmail: emailRef.current.value,
        Language: lang,
        Category: category,
        PaymentOption: paymentOption,
        Body: bodyref.current.value,
      };
    } else {
      data = {
        BrandID: appId,
        CustomerEmail: emailRef.current.value,
        Language: lang,
        Category: category,
        Body: bodyref.current.value,
      };
    }
    const sending = document.getElementById('submit-button');
    sending.innerHTML = t('ContactForm_Submitting');
    setDisable(true);

    SubmitContactUs(data).then(
      () => {
        setShow(false);
        document.getElementById('desktop-contact-us').style.visibility = 'hidden';
        document.getElementById('successMessage').style.visibility = 'visible';
        document.getElementById('successMessage').style.display = 'block';
        const focus = document.getElementById('successMessage');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },
      () => {
        setError(t('Error_Something_Went_Wrong'));
        setShow(true);
        sending.innerHTML = t('ContactForm_Submit_button');
        setDisable(false);
        const focus = document.getElementById('error_message');
        focus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    );
  }

  function handleSubmit(e) {
    setDescriptionValidateOnChange(true);
    setEmailValidateOnChange(true);

    const errorList = [];

    e.preventDefault();
    logAmpEvent(event.USER_CLICKED_SUBMIT);

    const emailError = validateEmail(emailRef);
    if (emailError !== undefined) {
      document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
      errorList.push(emailError);
    }

    if (appId === '') {
      errorList.push('app_error_message');
    } else {
      hideContactError('app_error_message');
    }
    if (category === '') {
      errorList.push('category_error_message');
    } else {
      hideContactError('category_error_message');
    }
    if (isPayment) {
      if (paymentOption === '') {
        document.getElementById('payment-option').classList.add('is-invalid');
        errorList.push('paymentOption_error_message');
      } else {
        if (document.getElementById('payment-option').classList.contains('is-invalid')) {
          document.getElementById('payment-option').classList.remove('is-invalid');
        }
        hideContactError('paymentOption_error_message');
      }
    }

    if (validateDescriptionBody(bodyref) !== undefined) {
      document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
      errorList.push(validateDescriptionBody(bodyref));
    }

    // Check validation error list

    showErrorFromList(errorList, widgetMode);

    if (errorList.length === 0) {
      sendAPI();
      setScreenReaderAlert(t('ScreenReader_ContactForm_Success'));
    } else {
      logAmpEvent(ampEvent.USER_ERROR_CONTACT, { 'Error Type': errorList });
      setScreenReaderAlert(t('ScreenReader_ContactForm_Error'));
    }
  }

  const handleTextareaChange = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <>
      {show && (
        <Alert
          variant="danger"
          id="error_message"
          className="mt-3"
          onClose={() => {
            setShow(false);
            setError('');
          }}
          dismissible
        >
          <p style={{ marginBottom: 0 }}>{error}</p>
        </Alert>
      )}
      <div id="successMessage">
        <h1>{t('ContactForm_Submitted')}</h1>
        <Link to={(widgetMode) ? `/widget/${brand}/${lang}${isMobile}` : `/${brand}/${lang}/categories`}>
          <Button className={`error-go-home-btn ${brand}-button`}>{t('ReturnHome_Btn')}</Button>
        </Link>
      </div>
      <div id="desktop-contact-us">
        <div id="skip-link-content">
          <h1>{t('General_Contact_Us')}</h1>
          <p className="contact-label-suggestion">{t('ContactForm_Fields_Required')}</p>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group
              className="form-groups"
              controlId="ContactForm_General_Email_"
              required
            >
              <fieldset>
                <Form.Label as="legend" id="aria-email" className="form-labels">
                  {t('ContactForm_General_Email_Label')}
                </Form.Label>
                <Form.Text className="muted email-help">
                  {t('ContactForm_General_Email_Help')}
                </Form.Text>
                <Form.Control
                  aria-labelledby="aria-email aria-email-warn"
                  ref={emailRef}
                  className="email-attr"
                  onBlur={() => {
                    setEmailValidateOnChange(true);
                    const curError = validateEmail(emailRef);
                    if (curError !== undefined) {
                      document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
                      showErrorFromList(curError);
                    } else {
                      if (document.getElementsByClassName('email-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('email-attr')[0].classList.remove('is-invalid');
                      }
                      showErrorFromList(validateEmail(emailRef));
                    }
                  }}
                  onChange={() => {
                    if (shouldValidateEmailOnChange) {
                      const curError = validateEmail(emailRef);
                      if (curError !== undefined) {
                        document.getElementsByClassName('email-attr')[0].classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else if (document.getElementsByClassName('email-attr')[0].classList.contains('is-invalid')) {
                        document.getElementsByClassName('email-attr')[0].classList.remove('is-invalid');
                      }
                    }
                  }}
                />
                <Form.Text
                  id="email_empty_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Email_Error')}
                </Form.Text>
                <Form.Text
                  id="email_invalid"
                  className="error-handling-messages"
                >
                  {t('ContactForm_General_Email_Validation')}
                </Form.Text>
                <Form.Text
                  id="email_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_Email_Character_Length_Error')}
                </Form.Text>
              </fieldset>
            </Form.Group>

            <Form.Group
              className="form-inline"
              controlId="ContactForm_AppID_"
              required
            >
              <fieldset>
                <Form.Label id="app_label" as="legend" className="form-labels">
                  {t('ContactForm_General_AppID_Label')}
                </Form.Label>
                <div className="web-mobile-btn">
                  <Form.Check>
                    <Button
                      className={`clickable-btn ${
                        mobileActive ? 'active-btn' : 'inactive-btn'
                      }`}
                      id="contact-mobile-btn"
                      name="ContactForm_AppID_"
                      onClick={() => {
                        handleWebMobileClick('PaySimply mobile app');
                      }}
                    >
                      <i className="fal fa-mobile-alt btn-icon" />
                      {t('ContactForm_AppID_Mobile')}
                    </Button>
                  </Form.Check>

                  <Form.Check>
                    <Button
                      className={`clickable-btn ${
                        webActive ? 'active-btn' : 'inactive-btn'
                      }`}
                      id="contact-web-btn"
                      name="ContactForm_AppID_"
                      onClick={() => {
                        handleWebMobileClick('PaySimply website');
                      }}
                    >
                      <i className="fal fa-window btn-icon" />
                      {t('ContactForm_AppID_Website')}
                    </Button>
                  </Form.Check>
                </div>
                <Form.Text
                  id="app_error_message"
                  className="error-handling-messages"
                >
                  {t('ContactForm_App_Validation_Error')}
                </Form.Text>

              </fieldset>
            </Form.Group>

            {/* Which app did you use? */}
            <fieldset className="paysimply-radio">
              <Form.Label id="category_label" as="legend" className="form-labels">
                {t('ContactForm_General_Category_Label')}
              </Form.Label>

              <RadioBuilder
                value="Payment Verification"
                content={(
                  <>
                    {t('ContactForm_Category_PaySimply_PaymentVerification')}
                    <div style={{ fontSize: 'smaller' }}>
                      {t('ContactForm_Category_PaySimply_PaymentVerification_Deets')}
                    </div>
                  </>
                )}
                clickHandler={handleShowFields}
              />

              <RadioBuilder
                value="Payment Issue"
                content={t('ContactForm_Category_PaySimply_PaymentIssue')}
                clickHandler={handleShowFields}
              />
              <RadioBuilder
                value="Missing Receipt"
                content={(
                  <>
                    {t('ContactForm_Category_PaySimply_MissingReceipt')}
                    <div style={{ fontSize: 'smaller' }}>
                      {t('ContactForm_Category_PaySimply_MissingReceipt_Deets')}
                    </div>
                  </>
                )}
                clickHandler={handleShowFields}
              />

              <RadioBuilder
                value="Data or account deletion request"
                content={t('ContactForm_Category_All_AccountDeletion')}
                selected={removeAccount}
                clickHandler={handleShowFields}
              />

              <RadioBuilder
                value="Other"
                content={t('ContactForm_PaymentOption_Other')}
                clickHandler={handleShowFields}
              />

              <Form.Text
                id="category_error_message"
                className="error-handling-messages"
              >
                {t('ContactForm_Category_Validation_Error')}
              </Form.Text>
            </fieldset>

            {/* Check your payment status online */}
            {showFields.paymentStatus && (
              <div className="div-check-status">
                <div id="status-top">
                  <span>{t('Contact_Paysimply_CheckStatusOnlineTitle')}</span>
                  <span>{t('Contact_Paysimply_CheckStatusOnlineText')}</span>
                </div>
                <div id="status-bottom">
                  <a
                    href={t('Link_CheckStatusWithUTM')}
                    target="_blank"
                    rel="noreferrer"
                    className="paysimply-button btn"
                    aria-label={t('Contact_Paysimply_CheckStatusOnlineBtnCheckPayStatus')}
                    onClick={
                    () => {
                      logAmpEvent(ampEvent.USER_CLICKED_CHECK_PAYMENT_STATUS);
                    }
                  }
                  >
                    {t('Contact_Paysimply_CheckStatusOnlineBtnCheckPayStatus')}
                  </a>
                </div>
              </div>
            )}

            {/* Payment option dropdown */}
            {showFields.dropdown && (
            <Form.Group
              className="form-groups"
              controlId="ContactForm_PaymentOption_"
            >
              <fieldset>
                <Form.Label
                  id="option_label"
                  as="legend"
                  className="form-labels"
                >
                  {t('ContactForm_General_PaymentOption_Label')}
                </Form.Label>

                {/* Dropdown */}
                <select
                  className="custom-select"
                  id="payment-option"
                  onChange={() => {
                    const currentVal = document.getElementById('payment-option').value;
                    logAmpEvent(event.USER_SELECTED_PAYMENT_OPTION_USED, { 'Payment Option': currentVal });
                    setPaymentOption(currentVal);
                    hideContactError('paymentOption_error_message');
                    if (document.getElementById('payment-option').classList.contains('is-invalid')) {
                      document.getElementById('payment-option').classList.remove('is-invalid');
                    }
                  }}
                >
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <option defaultValue="" />
                  <option value="Credit Card">{t('ContactForm_PaymentOption_Credit')}</option>
                  <option value="Interac eTransfer">{t('ContactForm_PaymentOption_eTransfer')}</option>
                  <option value="PayPal">{t('ContactForm_PaymentOption_PayPal')}</option>
                  <option value="In person at Canada Post">{t('ContactForm_PaymentOption_InPerson')}</option>
                  <option value="Alipay">{t('ContactForm_PaymentOption_Alipay')}</option>
                  <option value="Other">
                    {' '}
                    {t('ContactForm_PaymentOption_Other')}
                    {' '}
                  </option>
                </select>
              </fieldset>

              <Form.Text
                id="paymentOption_error_message"
                className="error-handling-messages"
              >
                {t('ContactForm_Payment_Option_Validation_Error')}
              </Form.Text>
            </Form.Group>
            )}

            {/* Re-send receipt */}
            {showFields.resendReceipt && (
              <div className="div-check-status">
                <div id="status-top">
                  <span>{t('Contact_PaySimply_ResendReceiptTitle')}</span>
                  <span>{t('Contact_PaySimply_ResendReceiptText')}</span>
                </div>
                <div id="status-bottom">
                  <a
                    href={t('Link_ResendOrPrintReceipt')}
                    target="_blank"
                    rel="noreferrer"
                    className="paysimply-button btn"
                    aria-label={t('Contact_PaySimply_ResendReceipt_LinkDescription')}
                    onClick={
                    () => {
                      logAmpEvent(ampEvent.USER_CLICKED_GET_YOUR_RECEIPT);
                    }
                  }
                  >
                    {t('Contact_Paysimply_ResendReceiptBtnTitle')}
                  </a>
                </div>
              </div>
            )}

            {/* How can we help */}
            {showFields.textArea && (
            <>
              <Form.Group
                className="form-groups"
                controlId="ContactForm_General_Body_"
                required
              >
                <fieldset>
                  <Form.Label as="legend" id="aria-desc" className="form-labels">
                    {t('ContactForm_General_Body_Label')}
                  </Form.Label>

                  <Form.Control
                    // onClick={()=>{logAmpEvent(event.USER_CLICK_CONTACT_TEXTBOX)}}
                    aria-labelledby="aria-desc"
                    as="textarea"
                    rows={8}
                    ref={bodyref}
                    className="description-attr"
                    onBlur={() => {
                      setDescriptionValidateOnChange(true);
                      const curError = validateDescriptionBody(bodyref);
                      if (curError !== undefined) {
                        document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
                        showErrorFromList(curError);
                      } else {
                        if (document.getElementsByClassName('description-attr')[0].classList.contains('is-invalid')) {
                          document.getElementsByClassName('description-attr')[0].classList.remove('is-invalid');
                        }
                        showErrorFromList(validateDescriptionBody(bodyref));
                      }
                    }}
                    onChange={(e) => {
                      handleTextareaChange(e);
                      if (descriptionValidateOnChange) {
                        const curError = validateDescriptionBody(bodyref);
                        if (curError !== undefined) {
                          document.getElementsByClassName('description-attr')[0].classList.add('is-invalid');
                          showErrorFromList(curError);
                        } else {
                          if (document.getElementsByClassName('description-attr')[0].classList.contains('is-invalid')) {
                            document.getElementsByClassName('description-attr')[0].classList.remove('is-invalid');
                          }
                          showErrorFromList(validateDescriptionBody(bodyref));
                        }
                      }
                    }}
                  />
                  <Form.Text
                    id="description_empty_error_message"
                    className="error-handling-messages"
                  >
                    {t('ContactForm_Body_Error')}
                  </Form.Text>
                  <Form.Text
                    id="description_error_message"
                    className="error-handling-messages"
                  >
                    {t('ContactForm_Body_Character_Length_Error')}
                  </Form.Text>
                </fieldset>
              </Form.Group>
              <RelevantFAQ searchResults={searchResults} brand={brand} lang={lang} />
              <Button
                id="submit-button"
                className="paysimply-button"
                type="submit"
                disabled={disable}
                size="lg"
                style={{ backgroundColor: '#2B4E7B' }}
              >
                {t('ContactForm_Submit_button')}
              </Button>
            </>
            )}

          </Form>
          <Alert className="mt-3 email-banner" show={process.env.REACT_APP_ENV === 'dev'}>
            On the test system, contact form emails will be sent to
            <strong> psidevstar@gmail.com</strong>
          </Alert>
        </div>
      </div>
    </>
  );
}

export default translate(ContactUsPaySimply);
