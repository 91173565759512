import React from 'react';
import { Redirect } from 'react-router-dom';
import { getAllBrands } from '../../configurations/Brands';
import NotFound from '../dynamicPages/NotFound';

function RouteNotFound({ match: { params: { brand, lang } } }) {
  const allBrands = getAllBrands();
  const allLang = ['en', 'fr'];
  if (allBrands.includes(brand.toLowerCase()) && allLang.includes(lang.toLowerCase())) {
    return <Redirect to={`/${brand}/${lang}/notFound`} />;
  }

  return <NotFound />;
}

export default RouteNotFound;
