import React from 'react';
import { t, translate } from 'react-switch-lang';
import { Link } from 'react-router-dom';
import './404AndErrorCommon.scss';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import { brandTitleGet } from '../../configurations/Brands';

function Error() {
  const { brand, lang } = useSelector((state) => state);
  DocumentTitle(t('PageTitle_Error').replace('xBrand', brandTitleGet(brand, lang)));
  return (
    <div id="error-container">
      <h1 id="error-text">{t('Error_Something_Went_Wrong')}</h1>
      <Link to={`/${brand}/${lang}/categories`}>
        <Button className={`error-go-home-btn ${brand}-button`}>{t('ReturnHome_Btn')}</Button>
      </Link>

    </div>
  );
}

export default translate(Error);
