import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { t } from 'react-switch-lang';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import LoadingSpinner from './widgetHelper/LoadingSpinner';
import { brandTitleGet } from '../../configurations/Brands';
import './WidgetCommon.scss';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { DocumentTitle } from '../../helperFunctions/DocumentTitle';
import WidgetError from './WidgetError';
import { DbConnect } from '../../helperFunctions/DbConnect';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';
import WidgetNotFound from './WidgetNotFound';

function WidgetSections(props) {
  const { sections, error, loading, notFound, categories, setFilteringByText } = props;
  const { brand, lang } = useSelector((state) => state);
  useEffect(() => {
    if (!loading && !notFound) {
      setFilteringByText((currentText) => (currentText || `${t('Widget_Search_Filtering_By_Text', null, lang)} ${categories.Title} - ${sections.Title}`));
    }
  }, [loading, notFound]);

  useEffect(() => {
    try {
      $('.ais-SearchBox-submit').attr('tabindex', -1);
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  }, [loading]);

  if (notFound) return <WidgetNotFound brand={brand} lang={lang} />;
  if (error) return <WidgetError />;
  if (loading) return <LoadingSpinner />;

  DocumentTitle(
    t('PageTitle_Brand_Articles', null, lang).replace(
      'xBrand',
      brandTitleGet(brand, lang)
    )
  );

  function populateArticleList(articles) {
    const isMobile = (window.location.href.includes('mobileapp') ? '?mobileapp' : '');

    const itemList = articles.Articles;
    const jsxObjectList = [];
    itemList.forEach((res) => {
      jsxObjectList.push(
        <Link
          className="widget-main-link"
          key={res.ArticleID}
          to={`/widget/${brand}/${lang}/articles/${res.ArticleID}${isMobile}`}
          onClick={() => {
            logAmpEvent(event.USER_CLICKED_SECTION_PAGE_ARTICLE_LINK, {
              'Article ID': res.ArticleID,
            });
          }}

        >
          <Card style={{ marginBottom: '15px' }} className="widget-main-card">
            <ReplaceTextWithElement
              text={res.Title}
              replaceWith={<i>Interac</i>}
              replaceKey="Interac"
            />
          </Card>
        </Link>
      );
    });
    return jsxObjectList;
  }

  function showSectionDescription() {
    if (sections.Description) {
      return (
        <div className="widget-sections-description">
          <ReplaceTextWithElement
            text={sections.Description}
            replaceWith={<i>Interac</i>}
            replaceKey="Interac"
          />
        </div>
      );
    }
    return null;
  }

  return (
    <div id="widget-main-flex-container">
      <div className="title-text">
        <ReplaceTextWithElement
          text={sections.Title}
          replaceWith={<i>Interac</i>}
          replaceKey="Interac"
        />
      </div>
      {showSectionDescription()}
      {populateArticleList(sections)}
    </div>
  );
}

export default DbConnect(WidgetSections, 'section');
