import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLanguage } from 'react-switch-lang';
import { checkBrandExists, checkBrandLangExists } from '../../configurations/Brands';
import { pageBuilder } from './RouterSharedFunction';
import NotFound from '../dynamicPages/NotFound';
import { actionSetBrand, actionSetLanguage } from '../../redux/Action';
import ListCategoryAndSection from '../dynamicPages/ListCategoryAndSection';
import Section from '../dynamicPages/Section';
import Articles from '../dynamicPages/Articles';

function RouteFour({ match: { params: { brand, lang, type, id } } }) {
  const [brandExist, setBrandExist] = useState(false);
  const [langExist, setLangExist] = useState(true);
  const [redirect, setRedirect] = useState(false);
  brand = brand.toLowerCase();
  lang = lang.toLowerCase();
  type = type.toLowerCase();
  id = parseInt(id.match(/^\d*/)[0], 10);
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkBrandExists(brand)) {
      if (checkBrandLangExists(brand, lang)) {
        dispatch(actionSetBrand(brand));
        dispatch(actionSetLanguage(lang));
        setLanguage(lang);
      } else {
        setLangExist(false);
      }
    } else {
      setRedirect(true);
    }
    setBrandExist(true);
  }, [brand, lang]);

  if (redirect) return <NotFound />;

  if (!langExist) return <NotFound />;

  if (brandExist && langExist) {
    // Return not found if ID is not a number
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(id)) {
      return pageBuilder('notFound', NotFound, lang, brand);
    }
    switch (type) {
      case 'category':
      case 'categories':
        return pageBuilder('listCategory', ListCategoryAndSection, lang, brand, type, id);
      case 'section':
      case 'sections':
        return pageBuilder('section', Section, lang, brand, type, id);
      case 'article':
      case 'articles':
        return pageBuilder('article', Articles, lang, brand, type, id);
      default:
        return pageBuilder('notFound', NotFound, lang, brand);
    }
  } else if (brandExist && !langExist) {
    return pageBuilder('notFound', NotFound, lang, brand);
  }
  return null;
}

export default RouteFour;
