import React from 'react';

/**
 * return a custom radio button.
 * @param  content {html} to be rendered in button.
 * @param  {Function} clickHandler onclick handler.
 * @param  {String} value value to be passed into the clickHandler.
 * @param  {String} group grouping for button.
 * @param  {Boolean} selected if button is selected by default.
 * @return {JSX.Element} Custom radio button.
 */
function RadioBuilder({ content, clickHandler, value, group, selected }) {
  const grouping = group ?? 'a';

  return (
    // eslint-disable-next-line
    <label
      className="card radio-card"
      onClick={clickHandler && typeof clickHandler === 'function' ? () => clickHandler(value) : null}
    >
      <input name={grouping} className="contact-radio" type="radio" defaultChecked={selected} />
      <span className="select-option">{content}</span>
    </label>
  );
}

export default RadioBuilder;
