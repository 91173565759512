import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import { t, translate } from 'react-switch-lang';
import { useSelector } from 'react-redux';
import { event, logAmpEvent } from '../../helperFunctions/Amplitude';
import { brandConfig, getFooterBorderTop, getFooterLinkColor } from '../../configurations/Brands';
import ReplaceTextWithElement from '../../helperFunctions/ReplaceTextWithElement';

function Footer() {
  const { brand, lang } = useSelector((state) => state);
  function disclaimerText() {
    if (brand === 'paysimply' || brand === 'directpay') {
      return (
        <div id="trademark-text">
          {brandConfig[brand].sqlName}
          {' '}
          {t('Footer_Provided_By_Text')}
        </div>
      );
    }
    return '';
  }

  function renderPhoneNumberNowPrepay() {
    return brand === 'nowprepay' && (
      <span
        className="footer-links"
        style={{ color: getFooterLinkColor(brand) }}
      >
        1-800-253-2111
      </span>
    );
  }

  function renderMapleSubText() {
    if (brand === 'canadapost-prepaid') {
      return (
        <p className="max-container-center" id="maple-text">
          <ReplaceTextWithElement
            text={t('Footer_Maple_Sub_Text')}
            replaceWith={<i>Interac</i>}
            replaceKey="Interac"
          />
        </p>
      );
    }
    return false;
  }

  function createLinks() {
    const footerLinkList = brandConfig[brand].footerLink;
    const linkArray = [];
    for (let i = 0; i < footerLinkList.length; i += 1) {
      linkArray.push(
        <span key={i}>
          <a
            className="footer-links"
            key={footerLinkList[i][0]}
            id={footerLinkList[i][0]}
            href={t(footerLinkList[i][1])}
            style={{ color: getFooterLinkColor(brand) }}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              logAmpEvent(event.USER_CLICKED_FOOTER_LINK, {
                Link: t(footerLinkList[i][1]),
                Name: t(footerLinkList[i][0]),
              });
            }}
          >
            {t(footerLinkList[i][0])}
          </a>
        </span>
      );
    }
    return linkArray;
  }

  const slpText = () => {
    if (brand === 'canadapost-singleloadprepaid') {
      return (
        <p className="max-container-center" id="maple-text">
          {t('Footer_SLP_Sub_Text')}
        </p>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  const createContactUsLink = () => {
    if (brand === 'canadapost-singleloadprepaid') {
      return (
        <a
          id="aria-footer-contact"
          className="footer-links"
          style={{ color: getFooterLinkColor(brand) }}
          href={t('Link_SLP_Contact_Page')}
          onClick={() => {
            logAmpEvent(event.USER_CLICKED_FOOTER_LINK, {
              Link: t('Link_SLP_Contact_Page'),
              Name: 'Contact Us',
            });
          }}
        >
          {t('General_Contact_Us')}
        </a>
      );
    }
    return (
      <Link
        id="aria-footer-contact"
        className="footer-links"
        style={{ color: getFooterLinkColor(brand) }}
        to={`/${brand}/${lang}/contactus`}
        onClick={() => {
          logAmpEvent(event.USER_CLICKED_FOOTER_LINK, {
            Link: `/${brand}/${lang}/contactus`,
            Name: 'Contact Us',
          });
        }}
      >
        {t('General_Contact_Us')}
      </Link>
    );
  };

  return (
    <footer
      id="footer-content"
      aria-label={t('LinkDescription_Footer')}
      role="contentinfo"
      className="footer-style"
      style={
        {
          backgroundColor: brandConfig[brand].theme.footerColor,
          borderTop: getFooterBorderTop(brand),
        }
      }
    >
      <div id="footer-text">
        {disclaimerText()}
        <span id="footer-links">
          {createLinks()}
          {createContactUsLink()}
          {renderPhoneNumberNowPrepay()}
        </span>
        {renderMapleSubText()}
        {slpText()}
      </div>
    </footer>
  );
}

export default translate(Footer);
